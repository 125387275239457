<template>
  <div>
    <div
      class="mfp-bg off-canvas off-canvas-left main-menu-overlay mfp-ready"
      :style="open_side_bar == false ? 'display: none;' : ''"
    ></div>
    <div
      class="mfp-wrap"
      :style="open_side_bar == false ? 'display: none;' : ''"
    >
      <div class="mfp-container" @click="closeNav()">
        <div id="mySidenav" class="sidenav">
          <div class="sidebar-menu no-scrollbar">
            <ul class="nav nav-sidebar nav-vertical nav-uppercase">
              <li
                class="header-search-form search-form html relative has-icon"
                style="padding: 20px"
              >
                <div class="header-search-form-wrapper">
                  <div
                    class="searchform-wrapper ux-search-box relative is-normal"
                  >
                    <form
                      method="get"
                      class="searchform"
                      action="https://laodl.com/"
                      role="search"
                    >
                      <div class="flex-row relative">
                        <div class="flex-col flex-grow">
                          <input
                            type="search"
                            class="search-field mb-0"
                            name="s"
                            value=""
                            id="s"
                            placeholder="Search&hellip;"
                          />
                        </div>
                        <div class="flex-col">
                          <button
                            type="submit"
                            class="
                              ux-search-submit
                              submit-button
                              secondary
                              button
                              icon
                              mb-0
                            "
                            aria-label="Submit"
                          >
                            <i class="icon-search"></i>
                          </button>
                        </div>
                      </div>
                      <div class="live-search-results text-left z-top"></div>
                    </form>
                  </div>
                </div>
              </li>
              <li
                class="
                  menu-item menu-item-type-custom menu-item-object-custom
                  current-menu-item current_page_item
                  menu-item-home menu-item-453
                "
              >
                <a href="/" aria-current="page">ໜ້າຫຼັກ</a>
              </li>
              <li
                class="
                  menu-item
                  menu-item-type-post_type
                  menu-item-object-page menu-item-268
                "
              >
                <a href="/previous-lotto">ຜົນອອກເລກ</a>
              </li>
              <li
                class="
                  menu-item
                  menu-item-type-post_type
                  menu-item-object-page menu-item-267
                "
              >
                <a href="/">ຮູບພາບຜູ້ໂຊກດີ</a>
              </li>
              <li
                class="
                  menu-item
                  menu-item-type-post_type
                  menu-item-object-page menu-item-265
                "
              >
                <a href="/">ເງື່ອນໄຂຮັບລາງວັນ</a>
              </li>
              <li
                class="
                  menu-item
                  menu-item-type-post_type
                  menu-item-object-page menu-item-1540
                "
              >
                <a href="/">ໂປຼໂມຊັ້ນ</a>
              </li>
              <li
                class="
                  menu-item
                  menu-item-type-post_type
                  menu-item-object-page menu-item-1969
                "
              >
                <a href="/">ວິທີການສະໝັກ</a>
              </li>
              <li class="html header-social-icons ml-0">
                <div class="social-icons follow-icons">
                  <a
                    href="https://www.facebook.com/dLaolottery/"
                    target="_blank"
                    data-label="Facebook"
                    rel="noopener noreferrer nofollow"
                    class="icon plain facebook tooltip"
                    title="Follow on Facebook"
                    ><i class="icon-facebook"></i></a
                  ><a
                    href="tel:021 41 42 43"
                    target="_blank"
                    data-label="Phone"
                    rel="noopener noreferrer nofollow"
                    class="icon plain phone tooltip"
                    title="Call us"
                    ><i class="icon-phone"></i
                  ></a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div id="wrapper">
      <header id="header" class="header has-sticky sticky-jump">
        <div
          class="header-wrapper"
          :class="on_scroll == true && on_resize == true ? 'stuck' : ''"
        >
          <div
            id="top-bar"
            class="header-top hide-for-sticky nav-dark hide-for-medium"
          >
            <div class="flex-row container">
              <div class="flex-col hide-for-medium flex-left">
                <ul
                  class="nav nav-left medium-nav-center nav-small nav-divided"
                ></ul>
              </div>

              <div class="flex-col hide-for-medium flex-center">
                <ul class="nav nav-center nav-small nav-divided"></ul>
              </div>

              <div class="flex-col hide-for-medium flex-right">
                <ul class="nav top-bar-nav nav-right nav-small nav-divided">
                  <li class="header-newsletter-item has-icon">
                    <a
                      href="#header-newsletter-signup"
                      class="tooltip"
                      title=""
                    >
                      <span class="header-newsletter-title hide-for-medium">
                        Tel. 021 41 42 43
                      </span>
                    </a>
                    <div
                      id="header-newsletter-signup"
                      class="
                        lightbox-by-id lightbox-content
                        mfp-hide
                        lightbox-white
                      "
                      style="max-width: 700px; padding: 0px"
                    >
                      <div class="row" id="row-1190237263">
                        <div class="col small-12 large-12">
                          <div class="col-inner">
                            ທີ່ຢູ່:ຖະໜົນ 23 ສິງຫາ,ບ້ານ ໜອງບອນ,ເມືອງ ໄຊເສດຖາ,
                            ນະຄອນຫຼວງວຽງຈັນ, ໂທລະສັບ: 021 41 42 43. ແຟັກ: 021
                            450 081. Email:
                            <a
                              href="cdn-cgi/l/email-protection.html"
                              class="__cf_email__"
                              data-cfemail="7c15121a133c38301d1330130808190e05521f1311"
                              >[email&#160;protected]</a
                            >
                            Email:
                            <a
                              href="cdn-cgi/l/email-protection.html"
                              class="__cf_email__"
                              data-cfemail="d5b1b9b4bae7e5e4e795bdbaa1b8b4bcb9fbb6bab8"
                              >[email&#160;protected]</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="html header-social-icons ml-0">
                    <div class="social-icons follow-icons">
                      <a
                        href="https://www.facebook.com/dLaolottery/"
                        target="_blank"
                        data-label="Facebook"
                        rel="noopener noreferrer nofollow"
                        class="icon plain facebook tooltip"
                        title="Follow on Facebook"
                        ><i class="icon-facebook"></i></a
                      ><a
                        href="tel:021 41 42 43"
                        target="_blank"
                        data-label="Phone"
                        rel="noopener noreferrer nofollow"
                        class="icon plain phone tooltip"
                        title="Call us"
                        ><i class="icon-phone"></i
                      ></a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="masthead" class="header-main">
            <div
              class="
                header-inner
                flex-row
                container
                logo-left
                medium-logo-center
              "
              role="navigation"
            >
              <!-- Logo -->
              <div id="logo" class="flex-col logo">
                <!-- Header logo -->
                <a
                  href="/"
                  title="หวยลาว ຫວຍດີຈິຕ້ອນ ສາຂາ3 ອິນຊີ Digital Lottery ผลหวยลาว - ຫວຍດີຈິຕ້ອນ ສາຂາ3 ອິນຊີ"
                  rel="home"
                >
                  <img
                    width="207"
                    height="87"
                    src="../wp-content/uploads/2019/10/Untitled-2-1.png"
                    class="header_logo header-logo"
                    alt="หวยลาว ຫວຍດີຈິຕ້ອນ ສາຂາ3 ອິນຊີ Digital Lottery ผลหวยลาว" />
                  <img
                    width="207"
                    height="87"
                    src="../wp-content/uploads/2019/10/Untitled-2-1.png"
                    class="header-logo-dark"
                    alt="หวยลาว ຫວຍດີຈິຕ້ອນ ສາຂາ3 ອິນຊີ Digital Lottery ผลหวยลาว"
                /></a>
              </div>

              <!-- Mobile Left Elements -->
              <div class="flex-col show-for-medium flex-left">
                <ul class="mobile-nav nav nav-left">
                  <li class="nav-icon has-icon">
                    <div class="header-button">
                      <a
                        href="#"
                        data-open="#main-menu"
                        data-pos="left"
                        data-bg="main-menu-overlay"
                        data-color=""
                        class="icon primary button round is-small"
                        aria-label="Menu"
                        aria-controls="main-menu"
                        aria-expanded="false"
                        @click="openNav()"
                      >
                        <i class="icon-menu"></i>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>

              <!-- Left Elements -->
              <div class="flex-col hide-for-medium flex-left flex-grow">
                <ul
                  class="
                    header-nav header-nav-main
                    nav nav-left nav-divided nav-size-xlarge nav-uppercase
                  "
                >
                  <li
                    id="menu-item-453"
                    class="
                      menu-item menu-item-type-custom menu-item-object-custom
                      current-menu-item current_page_item
                      menu-item-home menu-item-453
                      active
                    "
                  >
                    <a href="/" aria-current="page" class="nav-top-link"
                      >ໜ້າຫຼັກ</a
                    >
                  </li>
                  <li
                    id="menu-item-268"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-268
                    "
                  >
                    <a href="/previous-lotto" class="nav-top-link">ຜົນອອກເລກ</a>
                  </li>
                  <li
                    id="menu-item-267"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-267
                    "
                  >
                    <a href="/" class="nav-top-link">ຮູບພາບຜູ້ໂຊກດີ</a>
                  </li>
                  <li
                    id="menu-item-265"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-265
                    "
                  >
                    <a href="/" class="nav-top-link">ເງື່ອນໄຂຮັບລາງວັນ</a>
                  </li>
                  <li
                    id="menu-item-1540"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-1540
                    "
                  >
                    <a href="/" class="nav-top-link">ໂປຼໂມຊັ້ນ</a>
                  </li>
                  <li
                    id="menu-item-1969"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-1969
                    "
                  >
                    <a href="/" class="nav-top-link">ວິທີການສະໝັກ</a>
                  </li>
                </ul>
              </div>

              <!-- Right Elements -->
              <div class="flex-col hide-for-medium flex-right">
                <ul
                  class="
                    header-nav header-nav-main
                    nav nav-right nav-divided nav-size-xlarge nav-uppercase
                  "
                >
                  <li class="html header-button-1">
                    <div class="header-button">
                      <a
                        rel="noopener noreferrer"
                        href="https://line.me/R/ti/p/@qgr6804k"
                        target="_blank"
                        class="button primary"
                        style="border-radius: 99px"
                      >
                        <span>ສະໝັກຕົວແທນຂາຍ</span>
                      </a>
                    </div>
                  </li>

                  <li class="header-divider"></li>
                </ul>
              </div>

              <!-- Mobile Right Elements -->
              <div class="flex-col show-for-medium flex-right">
                <ul class="mobile-nav nav nav-right"></ul>
              </div>
            </div>

            <div class="container">
              <div class="top-divider full-width"></div>
            </div>
          </div>
          <div class="header-bg-container fill">
            <div class="header-bg-image fill"></div>
            <div class="header-bg-color fill"></div>
          </div>
        </div>
      </header>

      <main id="main" class="">
        <div id="content" role="main" class="content-area">
          <div class="slider-wrapper relative" id="slider-1970140661">
            <div
              class="
                slider
                slider-nav-circle
                slider-nav-large
                slider-nav-light
                slider-style-normal
              "
              data-flickity-options='{
            "cellAlign": "center",
            "imagesLoaded": true,
            "lazyLoad": 1,
            "freeScroll": false,
            "wrapAround": true,
            "autoPlay": 6000,
            "pauseAutoPlayOnHover" : true,
            "prevNextButtons": true,
            "contain" : true,
            "adaptiveHeight" : true,
            "dragThreshold" : 10,
            "percentPosition": true,
            "pageDots": true,
            "rightToLeft": false,
            "draggable": true,
            "selectedAttraction": 0.1,
            "parallax" : 0,
            "friction": 0.6        }'
            >
              <div
                class="img has-hover x md-x lg-x y md-y lg-y"
                id="image_345218859"
              >
                <div class="img-inner dark">
                  <img
                    width="5400"
                    height="1881"
                    src="../wp-content/uploads/2019/10/ເວບໄຊ-laodl.jpg"
                    class="attachment-original size-original"
                    alt=""
                    srcset="
                      ../wp-content/uploads/2019/10/ເວບໄຊ-laodl.jpg 5400w,
                      ../wp-content/uploads/2019/10/ເວບໄຊ-laodl-800x279.jpg 800w,
                      ../wp-content/uploads/2019/10/ເວບໄຊ-laodl-768x268.jpg 768w,
                      ../wp-content/uploads/2019/10/ເວບໄຊ-laodl-1400x488.jpg 1400w
                    "
                    sizes="(max-width: 5400px) 100vw, 5400px"
                  />
                </div>
              </div>

              <div
                class="img has-hover x md-x lg-x y md-y lg-y"
                id="image_2102425197"
              >
                <div class="img-inner dark"></div>
              </div>
            </div>

          </div>

          <section class="section dark" id="section_849591866">
            <div class="bg section-bg fill bg-fill bg-loaded"></div>

            <div class="section-content relative">
              <div class="row" id="row-1399952802">
                <div class="col small-12 large-12">
                  <div class="col-inner dark" style="margin: 0px 0px -44px 0px">
                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b
                        ><span
                          class="section-title-main"
                          style="font-size: 144%"
                          ><i class="icon-star-o"></i>ຜົນການອອກເລກລາງວັນ
                          ຫວຍພັດທະນາຮ່ວມໃຈ ຫວຍລາວ ລ່າສຸດ</span
                        ><b></b>
                      </h3>
                    </div>

                    <div class="row" id="row-1537992995">
                      <div class="col small-12 large-12">
                        <div class="col-inner">
                          <h3 style="text-align: center">
                            ຫວຍລາວ ມື້ນີ້
                            {{ ListDigit.work_date | formatDate }} ເລກອອກ
                          </h3>
                          <div class="text-center">
                            <div
                              class="is-divider divider clearfix"
                              style="max-width: 640px"
                            ></div>
                          </div>

                          <h3 style="text-align: center">ລາງວັນເລກ 6 ໂຕ</h3>

                          <div class="banner has-hover" id="banner-1354816385">
                            <div class="banner-inner fill">
                              <div class="banner-bg fill">
                                <div class="bg fill bg-fill"></div>
                              </div>
                              <div class="banner-layers container">
                                <div class="fill banner-link"></div>

                                <div
                                  id="text-box-1835287412"
                                  class="
                                    text-box
                                    banner-layer
                                    x50
                                    md-x5
                                    lg-x5
                                    y50
                                    md-y50
                                    lg-y50
                                    res-text
                                  "
                                >
                                  <div class="text dark text-shadow-5" id="result_tag">
                                    <div
                                      class="is-border"
                                      style="
                                        border-radius: 20px;
                                        border-width: 5px 5px 5px 5px;
                                      "
                                    ></div>
                                    <div class="text-inner text-center">
                                      <h1 class="uppercase">
                                        <span style="color: #ffff00">
                                          <span style="font-size: 77.76px">
                                            <b v-if="ListDigit.status == 1">{{ Digit6 }}</b>
                                            <b v-else>{{ random_6 }}</b>
                                          </span></span
                                        >
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <h3 style="text-align: center">
                            ຊື້ຖືກ 1000 ກີບ ໄດ້ຮັບເງິນສົດ 400,000,000 ກີບ.
                          </h3>
                          <div class="text-center">
                            <div
                              class="is-divider divider clearfix"
                              style="max-width: 640px"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" id="row-443299548">
                      <div class="col medium-4 small-12 large-4">
                        <div
                          class="col-inner"
                          style="margin: 0px 0px -15px 0px"
                        >
                          <h3 style="text-align: center">
                            <strong>ລາງວັນເລກ 5 ໂຕ</strong>
                          </h3>

                          <div class="banner has-hover" id="banner-1573502091">
                            <div class="banner-inner fill">
                              <div class="banner-bg fill">
                                <div class="bg fill bg-fill"></div>
                              </div>
                              <div class="banner-layers container">
                                <div class="fill banner-link"></div>

                                <div
                                  id="text-box-1547869432"
                                  class="
                                    text-box
                                    banner-layer
                                    x50
                                    md-x5
                                    lg-x5
                                    y50
                                    md-y50
                                    lg-y50
                                    res-text
                                  "
                                >
                                  <div class="text dark text-shadow-5">
                                    <div
                                      class="is-border"
                                      style="
                                        border-radius: 20px;
                                        border-width: 5px 5px 5px 5px;
                                      "
                                    ></div>
                                    <div class="text-inner text-center">
                                      <h1 class="uppercase">
                                        <span style="color: #ffff00">
                                          <span style="font-size: 77.76px">
                                            <b v-if="ListDigit.status == 1">{{ Digit5 }}</b>
                                            <b v-else>{{ random_5 }}</b>
                                          </span>
                                        </span>
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <h3 style="text-align: center">
                            ຊື້ຖືກ 1000 ກີບ ໄດ້ຮັບເງິນສົດ 40,000,000 ກີບ.
                          </h3>
                          <h3 style="text-align: center"></h3>
                        </div>
                      </div>

                      <div class="col medium-4 small-12 large-4">
                        <div
                          class="col-inner"
                          style="margin: 0px 0px -15px 0px"
                        >
                          <h3 style="text-align: center">
                            <strong>ລາງວັນເລກ 4</strong
                            ><strong style="font-size: 20.16px"></strong
                            ><strong style="font-size: 20.16px">ໂຕ</strong>
                          </h3>

                          <div class="banner has-hover" id="banner-1124070651">
                            <div class="banner-inner fill">
                              <div class="banner-bg fill">
                                <div class="bg fill bg-fill"></div>
                              </div>
                              <div class="banner-layers container">
                                <div class="fill banner-link"></div>

                                <div
                                  id="text-box-326039014"
                                  class="
                                    text-box
                                    banner-layer
                                    x50
                                    md-x5
                                    lg-x5
                                    y50
                                    md-y50
                                    lg-y50
                                    res-text
                                  "
                                >
                                  <div class="text dark text-shadow-5">
                                    <div
                                      class="is-border"
                                      style="
                                        border-radius: 20px;
                                        border-width: 5px 5px 5px 5px;
                                      "
                                    ></div>
                                    <div class="text-inner text-center">
                                      <h1 class="uppercase">
                                        <span style="color: #ffff00">
                                          <span style="font-size: 77.76px">
                                            <b v-if="ListDigit.status == 1">{{ Digit4 }}</b>
                                            <b v-else>{{ random_4 }}</b>
                                          </span>
                                        </span>
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <h3 style="text-align: center">
                            ຊື້ຖືກ 1000 ກີບ ໄດ້ຮັບເງິນສົດ 5,000,000 ກີບ.
                          </h3>
                        </div>
                      </div>

                      <div class="col medium-4 small-12 large-4">
                        <div class="col-inner" style="margin: 0px 0px 0px 0px">
                          <h3 style="text-align: center">
                            <strong>ລາງວັນເລກ 3 ໂຕ</strong>
                          </h3>

                          <div class="banner has-hover" id="banner-275630426">
                            <div class="banner-inner fill">
                              <div class="banner-bg fill">
                                <div class="bg fill bg-fill"></div>
                              </div>
                              <div class="banner-layers container">
                                <div class="fill banner-link"></div>

                                <div
                                  id="text-box-1337289486"
                                  class="
                                    text-box
                                    banner-layer
                                    x50
                                    md-x50
                                    lg-x50
                                    y10
                                    md-y50
                                    lg-y50
                                    res-text
                                  "
                                >
                                  <div class="text dark text-shadow-5">
                                    <div
                                      class="is-border"
                                      style="
                                        border-radius: 20px;
                                        border-width: 5px 5px 5px 5px;
                                      "
                                    ></div>
                                    <div class="text-inner text-center">
                                      <h1 class="uppercase">
                                        <span style="color: #ffff00">
                                          <span style="font-size: 77.76px">
                                            <b v-if="ListDigit.status == 1">{{ Digit3 }}</b>
                                            <b v-else>{{ random_3 }}</b>
                                          </span>
                                        </span>
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <h3 style="text-align: center">
                            ຊື້ຖືກ 1000 ກີບ ໄດ້ຮັບເງິນສົດ 500,000 ກີບ.
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div class="text-center">
                      <div
                        class="is-divider divider clearfix"
                        style="max-width: 640px"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-1000647606">
                <div class="col small-12 large-12">
                  <div class="col-inner dark" style="margin: 0px 0px -44px 0px">
                    <div
                      id="gap-1370901883"
                      class="gap-element clearfix"
                      style="display: block; height: auto"
                    ></div>

                    <div class="row" id="row-1127463148">
                      <div class="col medium-6 small-12 large-6">
                        <div
                          class="col-inner"
                          style="margin: 0px 0px -15px 0px"
                        >
                          <h3 style="text-align: center">
                            <strong>ລາງວັນເລກ 2 ໂຕ</strong>
                          </h3>

                          <div class="banner has-hover" id="banner-874564019">
                            <div class="banner-inner fill">
                              <div class="banner-bg fill">
                                <div class="bg fill bg-fill"></div>
                              </div>
                              <div class="banner-layers container">
                                <div class="fill banner-link"></div>

                                <div
                                  id="text-box-1143000626"
                                  class="
                                    text-box
                                    banner-layer
                                    x50
                                    md-x50
                                    lg-x50
                                    y50
                                    md-y50
                                    lg-y50
                                    res-text
                                  "
                                >
                                  <div class="text dark text-shadow-5">
                                    <div
                                      class="is-border"
                                      style="
                                        border-radius: 20px;
                                        border-width: 5px 5px 5px 5px;
                                      "
                                    ></div>
                                    <div class="text-inner text-center">
                                      <h1 class="uppercase">
                                        <span style="color: #ffff00">
                                          <span style="font-size: 77.76px">
                                            <b v-if="ListDigit.status == 1">{{ Digit2 }}</b>
                                            <b v-else>{{ random_2 }}</b>
                                          </span>
                                        </span>
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <h3 style="text-align: center">
                            ຊື້ຖືກ 1000 ກີບ ໄດ້ຮັບເງິນສົດ 60,000 ກີບ.
                          </h3>
                        </div>
                      </div>

                      <div class="col medium-6 small-12 large-6">
                        <div
                          class="col-inner"
                          style="margin: 0px 0px -15px 0px"
                        >
                          <h3 style="text-align: center">
                            <strong>ລາງວັນເລກ 1 ໂຕ</strong>
                          </h3>

                          <div class="banner has-hover" id="banner-136071884">
                            <div class="banner-inner fill">
                              <div class="banner-bg fill">
                                <div class="bg fill bg-fill"></div>
                              </div>
                              <div class="banner-layers container">
                                <div class="fill banner-link"></div>

                                <div
                                  id="text-box-676879988"
                                  class="
                                    text-box
                                    banner-layer
                                    x50
                                    md-x50
                                    lg-x50
                                    y50
                                    md-y50
                                    lg-y50
                                    res-text
                                  "
                                >
                                  <div class="text dark text-shadow-5">
                                    <div
                                      class="is-border"
                                      style="
                                        border-radius: 20px;
                                        border-width: 5px 5px 5px 5px;
                                      "
                                    ></div>
                                    <div class="text-inner text-center">
                                      <h1 class="uppercase">
                                        <span style="color: #ffff00">
                                          <span style="font-size: 77.76px">
                                            <b v-if="ListDigit.status == 1">{{ Digit1 }}</b>
                                            <b v-else>{{ random_1 }}</b>
                                          </span>
                                        </span>
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <h3 style="text-align: center">
                            ຊື້ຖືກ 1000 ກີບ ໄດ້ຮັບເງິນສົດ 5,000 ກີບ.
                          </h3>
                          <h3 style="text-align: center"></h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="section" id="section_2019356090">
            <div class="bg section-bg fill bg-fill bg-loaded"></div>

            <div class="section-content relative">
              <div class="row" id="row-1344395308">
                <div class="col small-12 large-12">
                  <div
                    class="col-inner text-center"
                    style="padding: 13px 0px 0px 0px; margin: 0px 0px -43px 0px"
                  >
                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b
                        ><span
                          class="section-title-main"
                          style="font-size: 130%; color: rgb(95, 92, 92)"
                          >ກວດສອບຜົນການອອກຫວຍຍ້ອນຫຼັງ</span
                        ><b></b>
                      </h3>
                    </div>

                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_1825866440"
                    >
                      <a
                        class=""
                        href="previous-lotto"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div class="img-inner dark">
                          <img
                            width="1020"
                            height="291"
                            src="../wp-content/uploads/2019/03/lotto-dl-ladl-1400x400.jpg"
                            class="attachment-large size-large"
                            alt=""
                            srcset="
                              ../wp-content/uploads/2019/03/lotto-dl-ladl-1400x400.jpg 1400w,
                              ../wp-content/uploads/2019/03/lotto-dl-ladl-800x228.jpg 800w,
                              ../wp-content/uploads/2019/03/lotto-dl-ladl-768x219.jpg 768w
                            "
                            sizes="(max-width: 1020px) 100vw, 1020px"
                          />
                        </div>
                      </a>
                    </div>

                    <div
                      id="gap-254369011"
                      class="gap-element clearfix"
                      style="display: block; height: auto"
                    ></div>

                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b
                        ><span
                          class="section-title-main"
                          style="font-size: 130%; color: rgb(89, 82, 82)"
                          >ຕິດຕາມຜົນການອອກຫວຍໄດ້ກ່ອນໝູ່ ຕິດຕໍ່ເຮົາງ່າຍໆ</span
                        ><b></b>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-819177296">
                <div class="col medium-4 small-12 large-4">
                  <div class="col-inner">
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_1841725139"
                    >
                      <a class="" href="https://line.me/R/ti/p/@qgr6804k">
                        <div class="img-inner dark">
                          <img
                            width="312"
                            height="67"
                            src="../wp-content/uploads/2021/06/444.png"
                            class="attachment-large size-large"
                            alt=""
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col medium-4 small-12 large-4">
                  <div class="col-inner">
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_606406179"
                    >
                      <a
                        class=""
                        href="https://www.messenger.com/t/598578720169375/?ref=pages_you_manage&amp;messaging_source=source%3Apages%3Amessage_shortlink"
                      >
                        <div class="img-inner dark">
                          <img
                            width="312"
                            height="69"
                            src="../wp-content/uploads/2021/06/Mess.png"
                            class="attachment-large size-large"
                            alt=""
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col medium-4 small-12 large-4">
                  <div class="col-inner">
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_1386087555"
                    >
                      <a
                        class=""
                        href="https://api.whatsapp.com/send?phone=+8562058895533"
                      >
                        <div class="img-inner dark">
                          <img
                            width="312"
                            height="69"
                            src="../wp-content/uploads/2021/06/whatapp.png"
                            class="attachment-large size-large"
                            alt=""
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-653756592">
                <div class="col medium-4 small-12 large-4">
                  <div class="col-inner">
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_1699141243"
                    >
                      <a
                        class=""
                        href="https://www.youtube.com/channel/UC_hY7PHas5MHgf-T_d0I68w"
                      >
                        <div class="img-inner dark">
                          <img
                            width="312"
                            height="69"
                            src="../wp-content/uploads/2021/06/youtube.png"
                            class="attachment-large size-large"
                            alt=""
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col medium-4 small-12 large-4">
                  <div class="col-inner">
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_667230022"
                    >
                      <div class="img-inner dark">
                        <img
                          width="312"
                          height="69"
                          src="../wp-content/uploads/2021/07/Tiktok.png"
                          class="attachment-large size-large"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col medium-4 small-12 large-4">
                  <div class="col-inner">
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_1015217786"
                    >
                      <a class="" href="tel:021414243">
                        <div class="img-inner dark">
                          <img
                            width="312"
                            height="69"
                            src="../wp-content/uploads/2021/06/CALL.png"
                            class="attachment-large size-large"
                            alt=""
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="gap-1081749030"
                class="gap-element clearfix"
                style="display: block; height: auto"
              ></div>
            </div>
          </section>

          <section class="section" id="section_1110333115">
            <div class="bg section-bg fill bg-fill bg-loaded"></div>

            <div class="section-content relative">
              <div class="row" id="row-1962117069">
                <div class="col small-12 large-12">
                  <div class="col-inner text-center">
                    <h2 style="text-align: center"><b>ບັນຫາພາລວຍ</b></h2>

                    <div
                      class="
                        row
                        large-columns-4
                        medium-columns-3
                        small-columns-2
                        slider
                        row-slider
                        slider-nav-reveal
                      "
                      data-flickity-options='{"imagesLoaded": true, "groupCells": "100%", "dragThreshold" : 5, "cellAlign": "left","wrapAround": true,"prevNextButtons": true,"percentPosition": true,"pageDots": false, "rightToLeft": false, "autoPlay" : false}'
                    >
                      <div class="gallery-col col">
                        <div class="col-inner">
                          <a
                            class="image-lightbox lightbox-gallery"
                            href="../wp-content/uploads/2021/11/55555555555-566x800.jpg"
                            title=""
                          >
                            <div
                              class="box has-hover gallery-box box-overlay dark"
                            >
                              <div class="box-image">
                                <img
                                  width="283"
                                  height="400"
                                  src="../wp-content/uploads/2021/11/55555555555-283x400.jpg"
                                  class="attachment-medium size-medium"
                                  alt=""
                                  ids="8485,8452,8451,8459,8461,8462,8463,8464,8465,8467,8470,8468"
                                  type="slider"
                                  sizes="(max-width: 283px) 100vw, 283px"
                                />
                                <div
                                  class="overlay fill"
                                  style="background-color: rgba(0, 0, 0, 0.15)"
                                ></div>
                              </div>
                              <div class="box-text text-left">
                                <p></p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="gallery-col col">
                        <div class="col-inner">
                          <a
                            class="image-lightbox lightbox-gallery"
                            href="../wp-content/uploads/2021/11/31121-2-566x800.jpg"
                            title=""
                          >
                            <div
                              class="box has-hover gallery-box box-overlay dark"
                            >
                              <div class="box-image">
                                <img
                                  width="283"
                                  height="400"
                                  src="../wp-content/uploads/2021/11/31121-2-283x400.jpg"
                                  class="attachment-medium size-medium"
                                  alt=""
                                  ids="8485,8452,8451,8459,8461,8462,8463,8464,8465,8467,8470,8468"
                                  type="slider"
                                  sizes="(max-width: 283px) 100vw, 283px"
                                />
                                <div
                                  class="overlay fill"
                                  style="background-color: rgba(0, 0, 0, 0.15)"
                                ></div>
                              </div>
                              <div class="box-text text-left">
                                <p></p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="gallery-col col">
                        <div class="col-inner">
                          <a
                            class="image-lightbox lightbox-gallery"
                            href="../wp-content/uploads/2021/11/31121-3-566x800.jpg"
                            title=""
                          >
                            <div
                              class="box has-hover gallery-box box-overlay dark"
                            >
                              <div class="box-image">
                                <img
                                  width="283"
                                  height="400"
                                  src="../wp-content/uploads/2021/11/31121-3-283x400.jpg"
                                  class="attachment-medium size-medium"
                                  alt=""
                                  ids="8485,8452,8451,8459,8461,8462,8463,8464,8465,8467,8470,8468"
                                  type="slider"
                                  sizes="(max-width: 283px) 100vw, 283px"
                                />
                                <div
                                  class="overlay fill"
                                  style="background-color: rgba(0, 0, 0, 0.15)"
                                ></div>
                              </div>
                              <div class="box-text text-left">
                                <p></p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="gallery-col col">
                        <div class="col-inner">
                          <a
                            class="image-lightbox lightbox-gallery"
                            href="../wp-content/uploads/2021/11/1-1-566x800.jpg"
                            title=""
                          >
                            <div
                              class="box has-hover gallery-box box-overlay dark"
                            >
                              <div class="box-image">
                                <img
                                  width="283"
                                  height="400"
                                  src="../wp-content/uploads/2021/11/1-1-283x400.jpg"
                                  class="attachment-medium size-medium"
                                  alt=""
                                  ids="8485,8452,8451,8459,8461,8462,8463,8464,8465,8467,8470,8468"
                                  type="slider"
                                  sizes="(max-width: 283px) 100vw, 283px"
                                />
                                <div
                                  class="overlay fill"
                                  style="background-color: rgba(0, 0, 0, 0.15)"
                                ></div>
                              </div>
                              <div class="box-text text-left">
                                <p></p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="gallery-col col">
                        <div class="col-inner">
                          <a
                            class="image-lightbox lightbox-gallery"
                            href="../wp-content/uploads/2021/11/2-566x800.jpg"
                            title=""
                          >
                            <div
                              class="box has-hover gallery-box box-overlay dark"
                            >
                              <div class="box-image">
                                <img
                                  width="283"
                                  height="400"
                                  src="../wp-content/uploads/2021/11/2-283x400.jpg"
                                  class="attachment-medium size-medium"
                                  alt=""
                                  ids="8485,8452,8451,8459,8461,8462,8463,8464,8465,8467,8470,8468"
                                  type="slider"
                                  sizes="(max-width: 283px) 100vw, 283px"
                                />
                                <div
                                  class="overlay fill"
                                  style="background-color: rgba(0, 0, 0, 0.15)"
                                ></div>
                              </div>
                              <div class="box-text text-left">
                                <p></p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="gallery-col col">
                        <div class="col-inner">
                          <a
                            class="image-lightbox lightbox-gallery"
                            href="../wp-content/uploads/2021/11/3-566x800.jpg"
                            title=""
                          >
                            <div
                              class="box has-hover gallery-box box-overlay dark"
                            >
                              <div class="box-image">
                                <img
                                  width="283"
                                  height="400"
                                  src="../wp-content/uploads/2021/11/3-283x400.jpg"
                                  class="attachment-medium size-medium"
                                  alt=""
                                  ids="8485,8452,8451,8459,8461,8462,8463,8464,8465,8467,8470,8468"
                                  type="slider"
                                  sizes="(max-width: 283px) 100vw, 283px"
                                />
                                <div
                                  class="overlay fill"
                                  style="background-color: rgba(0, 0, 0, 0.15)"
                                ></div>
                              </div>
                              <div class="box-text text-left">
                                <p></p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="gallery-col col">
                        <div class="col-inner">
                          <a
                            class="image-lightbox lightbox-gallery"
                            href="../wp-content/uploads/2021/11/4-1-566x800.jpg"
                            title=""
                          >
                            <div
                              class="box has-hover gallery-box box-overlay dark"
                            >
                              <div class="box-image">
                                <img
                                  width="283"
                                  height="400"
                                  src="../wp-content/uploads/2021/11/4-1-283x400.jpg"
                                  class="attachment-medium size-medium"
                                  alt=""
                                  ids="8485,8452,8451,8459,8461,8462,8463,8464,8465,8467,8470,8468"
                                  type="slider"
                                  sizes="(max-width: 283px) 100vw, 283px"
                                />
                                <div
                                  class="overlay fill"
                                  style="background-color: rgba(0, 0, 0, 0.15)"
                                ></div>
                              </div>
                              <div class="box-text text-left">
                                <p></p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="gallery-col col">
                        <div class="col-inner">
                          <a
                            class="image-lightbox lightbox-gallery"
                            href="../wp-content/uploads/2021/11/5-1-566x800.jpg"
                            title=""
                          >
                            <div
                              class="box has-hover gallery-box box-overlay dark"
                            >
                              <div class="box-image">
                                <img
                                  width="283"
                                  height="400"
                                  src="../wp-content/uploads/2021/11/5-1-283x400.jpg"
                                  class="attachment-medium size-medium"
                                  alt=""
                                  ids="8485,8452,8451,8459,8461,8462,8463,8464,8465,8467,8470,8468"
                                  type="slider"
                                  sizes="(max-width: 283px) 100vw, 283px"
                                />
                                <div
                                  class="overlay fill"
                                  style="background-color: rgba(0, 0, 0, 0.15)"
                                ></div>
                              </div>
                              <div class="box-text text-left">
                                <p></p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="gallery-col col">
                        <div class="col-inner">
                          <a
                            class="image-lightbox lightbox-gallery"
                            href="../wp-content/uploads/2021/11/6-1-566x800.jpg"
                            title=""
                          >
                            <div
                              class="box has-hover gallery-box box-overlay dark"
                            >
                              <div class="box-image">
                                <img
                                  width="283"
                                  height="400"
                                  src="../wp-content/uploads/2021/11/6-1-283x400.jpg"
                                  class="attachment-medium size-medium"
                                  alt=""
                                  ids="8485,8452,8451,8459,8461,8462,8463,8464,8465,8467,8470,8468"
                                  type="slider"
                                  sizes="(max-width: 283px) 100vw, 283px"
                                />
                                <div
                                  class="overlay fill"
                                  style="background-color: rgba(0, 0, 0, 0.15)"
                                ></div>
                              </div>
                              <div class="box-text text-left">
                                <p></p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="gallery-col col">
                        <div class="col-inner">
                          <a
                            class="image-lightbox lightbox-gallery"
                            href="../wp-content/uploads/2021/11/7-1-566x800.jpg"
                            title=""
                          >
                            <div
                              class="box has-hover gallery-box box-overlay dark"
                            >
                              <div class="box-image">
                                <img
                                  width="283"
                                  height="400"
                                  src="../wp-content/uploads/2021/11/7-1-283x400.jpg"
                                  class="attachment-medium size-medium"
                                  alt=""
                                  ids="8485,8452,8451,8459,8461,8462,8463,8464,8465,8467,8470,8468"
                                  type="slider"
                                  sizes="(max-width: 283px) 100vw, 283px"
                                />
                                <div
                                  class="overlay fill"
                                  style="background-color: rgba(0, 0, 0, 0.15)"
                                ></div>
                              </div>
                              <div class="box-text text-left">
                                <p></p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="gallery-col col">
                        <div class="col-inner">
                          <a
                            class="image-lightbox lightbox-gallery"
                            href="../wp-content/uploads/2021/11/8-1-566x800.jpg"
                            title=""
                          >
                            <div
                              class="box has-hover gallery-box box-overlay dark"
                            >
                              <div class="box-image">
                                <img
                                  width="283"
                                  height="400"
                                  src="../wp-content/uploads/2021/11/8-1-283x400.jpg"
                                  class="attachment-medium size-medium"
                                  alt=""
                                  ids="8485,8452,8451,8459,8461,8462,8463,8464,8465,8467,8470,8468"
                                  type="slider"
                                  sizes="(max-width: 283px) 100vw, 283px"
                                />
                                <div
                                  class="overlay fill"
                                  style="background-color: rgba(0, 0, 0, 0.15)"
                                ></div>
                              </div>
                              <div class="box-text text-left">
                                <p></p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="gallery-col col">
                        <div class="col-inner">
                          <a
                            class="image-lightbox lightbox-gallery"
                            href="../wp-content/uploads/2021/11/9-1-566x800.jpg"
                            title=""
                          >
                            <div
                              class="box has-hover gallery-box box-overlay dark"
                            >
                              <div class="box-image">
                                <img
                                  width="283"
                                  height="400"
                                  src="../wp-content/uploads/2021/11/9-1-283x400.jpg"
                                  class="attachment-medium size-medium"
                                  alt=""
                                  ids="8485,8452,8451,8459,8461,8462,8463,8464,8465,8467,8470,8468"
                                  type="slider"
                                  sizes="(max-width: 283px) 100vw, 283px"
                                />
                                <div
                                  class="overlay fill"
                                  style="background-color: rgba(0, 0, 0, 0.15)"
                                ></div>
                              </div>
                              <div class="box-text text-left">
                                <p></p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>

                    <a
                      rel="noopener noreferrer"
                      href="https://line.me/R/ti/p/@qgr6804k"
                      target="_blank"
                      class="button secondary lowercase"
                      style="border-radius: 99px"
                    >
                      <span>ຄລິກເບີ່ງບັນຫາກ່ອນຫວຍອອກ</span>
                      <i class="icon-angle-right"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="row" id="row-240528164">
                <div class="col small-12 large-12">
                  <div class="col-inner">
                    <div class="row" id="row-1606084839">
                      <div class="col medium-6 small-12 large-6">
                        <div class="col-inner">
                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_539987042"
                          >
                            <div class="img-inner dark">
                              <img
                                width="800"
                                height="800"
                                src="../wp-content/uploads/2021/11/16565656566665444999-800x800.jpg"
                                class="attachment-large size-large"
                                alt=""
                                sizes="(max-width: 800px) 100vw, 800px"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col medium-6 small-12 large-6">
                        <div class="col-inner">
                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_616291898"
                          >
                            <div class="img-inner dark">
                              <img
                                width="800"
                                height="800"
                                src="../wp-content/uploads/2021/10/00112223333-800x800.jpg"
                                class="attachment-large size-large"
                                alt=""
                                sizes="(max-width: 800px) 100vw, 800px"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" id="row-1095616870">
                      <div class="col medium-6 small-12 large-6">
                        <div class="col-inner">
                          <div class="container section-title-container">
                            <h3 class="section-title section-title-center">
                              <b></b
                              ><span class="section-title-main"
                                >ລຸ້ນໂຊກກັບ ຫວຍອິນຊີ</span
                              ><b></b>
                            </h3>
                          </div>

                          <div
                            class="video video-fit mb"
                            style="padding-top: 56.25%"
                          >
                            <iframe
                              title="หวยลาว ຫວຍອິນຊີ"
                              width="1020"
                              height="765"
                              src="https://www.youtube.com/embed/tCB5aHpmyMo?feature=oembed"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                          </div>
                        </div>
                      </div>

                      <div class="col medium-6 small-12 large-6">
                        <div class="col-inner">
                          <div class="container section-title-container">
                            <h3 class="section-title section-title-center">
                              <b></b
                              ><span class="section-title-main"
                                >ຊ່ຽງຊີ ບອກດວງຊະຕາ</span
                              ><b></b>
                            </h3>
                          </div>

                          <div
                            class="video video-fit mb"
                            style="padding-top: 56.25%"
                          >
                            <p>
                              <iframe
                                title="ໃບຊ່ຽງຊີ"
                                width="1020"
                                height="765"
                                src="https://www.youtube.com/embed/BV1Chmv_X04?feature=oembed"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                              ></iframe>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b
                        ><span class="section-title-main">ຄວາມຝັນບອກໂຊກລາບ</span
                        ><b></b>
                      </h3>
                    </div>

                    <div class="slider-wrapper relative" id="slider-987351440">
                      <div
                        class="
                          slider
                          slider-nav-circle
                          slider-nav-large
                          slider-nav-light
                          slider-style-normal
                        "
                        data-flickity-options='{
            "cellAlign": "center",
            "imagesLoaded": true,
            "lazyLoad": 1,
            "freeScroll": false,
            "wrapAround": true,
            "autoPlay": 3000,
            "pauseAutoPlayOnHover" : true,
            "prevNextButtons": true,
            "contain" : true,
            "adaptiveHeight" : true,
            "dragThreshold" : 10,
            "percentPosition": true,
            "pageDots": true,
            "rightToLeft": false,
            "draggable": true,
            "selectedAttraction": 0.1,
            "parallax" : 0,
            "friction": 0.6        }'
                      >
                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_684293623"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/01-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_563397233"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/02-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_2011948901"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/03-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_1363647342"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/04-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_425022596"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/05-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_2011013779"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/06-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_988064213"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/07-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_733917722"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/08-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_2097339612"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/09-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_284959599"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/10-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_1942370831"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/11-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_1035090637"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/12-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_2129949364"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/13-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_145594713"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/14-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_39447698"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/15-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_1991450528"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/16-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_1576831177"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/17-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_769945379"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/18-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_694535591"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/19-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_583980146"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/20-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_860154057"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/21-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_668760854"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/22-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_13567035"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/23-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_626352136"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/24-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_1080011068"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/25-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_105823117"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/26-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_1137055945"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/27-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_1684923582"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/28-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_435120288"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/29-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_1178588392"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/30-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_1834213094"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/31-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_108964434"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/32-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_1413532932"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/33-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_1217815508"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/34-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_201604736"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/35-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_544794922"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/36-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_78466746"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/37-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_521573244"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/38-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_1180606487"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/39-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>

                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_1238290241"
                        >
                          <div class="img-inner dark">
                            <img
                              width="800"
                              height="800"
                              src="../wp-content/uploads/2021/06/40-800x800.jpg"
                              class="attachment-large size-large"
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-1796962572">
                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b
                        ><span class="section-title-main">ຮ້ານ ແວເຮົາ</span
                        ><b></b>
                      </h3>
                    </div>

                    <div class="video video-fit mb" style="padding-top: 56.25%">
                      <p>
                        <iframe
                          title="ກ່ອງໄມ້ຈີ້ມແຂ້ວອັດສະລິຍະ  || ແວ່ເຮົາ Ware House"
                          width="1020"
                          height="574"
                          src="https://www.youtube.com/embed/alJIYiFanIE?feature=oembed"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b
                        ><span class="section-title-main">ຮ້ານ ອອນຊອນ</span
                        ><b></b>
                      </h3>
                    </div>

                    <div class="video video-fit mb" style="padding-top: 56.25%">
                      <p>
                        <iframe
                          title="ຢ້ຽມຢາມໂຮງງານນຳສະກັດຫມາກຂາມປ້ອມ"
                          width="1020"
                          height="574"
                          src="https://www.youtube.com/embed/wnlAkpeilfQ?feature=oembed"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-340040072">
                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b><span class="section-title-main">ຫວຍອິນຊີ</span
                        ><b></b>
                      </h3>
                    </div>

                    <div class="video video-fit mb" style="padding-top: 56.25%">
                      <p>
                        <iframe
                          title="ຂອບໃຈເດີ ขอบใจเด้อ Cover MV Byຫວຍດິຈີຕ້ອນ ສາຂາ 3 ອິນຊີ"
                          width="1020"
                          height="574"
                          src="https://www.youtube.com/embed/hIFxA_C3Fec?feature=oembed"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b
                        ><span class="section-title-main">ຄວາມຝັນບອກໂຊກລາບ</span
                        ><b></b>
                      </h3>
                    </div>

                    <div class="video video-fit mb" style="padding-top: 56.25%">
                      <p>
                        <iframe
                          title="หวยเด็ด ຄວາມຝັນບອກໂຊກລາບ"
                          width="1020"
                          height="574"
                          src="https://www.youtube.com/embed/qO2s9D0BdHc?feature=oembed"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-912793728">
                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <h3 style="text-align: center">ເຄັດລັບຖືກຫວຍ ທຸກງວດ</h3>
                    <div class="video video-fit mb" style="padding-top: 56.25%">
                      <p>
                        <iframe
                          title="ใกล้แค่เอื้อม!! อยากถูกหวย ทุกงวด  มีโชคก้อนโต เค้า ทำกันแบบนี้นี่เอง!!"
                          width="1020"
                          height="574"
                          src="https://www.youtube.com/embed/bDqYZNyT4NY?feature=oembed"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <h3 style="text-align: center">ບູຊາ ພະປະຈຳໂຕ</h3>
                    <div class="video video-fit mb" style="padding-top: 56.25%">
                      <p>
                        <iframe
                          title="เทวดาประจำตัวทุกคนมี บูชาแบบนี้  จะเจริญรุ่งเรือง ชีวิตดีในพริบตา"
                          width="1020"
                          height="574"
                          src="https://www.youtube.com/embed/8m7tkk340oU?feature=oembed"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-2042063279">
                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b
                        ><span class="section-title-main">ວິທີເຮັດອາຫານ</span
                        ><b></b>
                      </h3>
                    </div>

                    <div class="video video-fit mb" style="padding-top: 56.25%">
                      <p>
                        <iframe
                          title="5 นาที ทำไข่เค็ม(แห้ง) โครตง่าย ไม่ต้องต้มน้ำเกลือ(12 วันทานได้เลย)ไข่ไก่จ้า 11 มกราคม ค.ศ. 2018"
                          width="1020"
                          height="574"
                          src="https://www.youtube.com/embed/avYHTBMenwo?list=PL-c7aJHBQim_6SpomQI37S1A38ULQdnu1"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b><span class="section-title-main">MUSIC</span
                        ><b></b>
                      </h3>
                    </div>

                    <div class="video video-fit mb" style="padding-top: 56.25%">
                      <p>
                        <iframe
                          title="ຜົ້ງສາລີແດນງາມ  | ผ้งสาลีแดนงาม | ເມລິສາ ລຸນລາວົງ |  (  Cover ອຳມະຕະເພງລາວ )"
                          width="1020"
                          height="574"
                          src="https://www.youtube.com/embed/ttnU5HphitQ?feature=oembed"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-1150510705">
                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b
                        ><span class="section-title-main"
                          >ຕຳນານເມືອງບັງບົດແກ້ງຫຼີ່ຜີ</span
                        ><b></b>
                      </h3>
                    </div>

                    <div class="video video-fit mb" style="padding-top: 56.25%">
                      <p>
                        <iframe
                          title="ຕຳນານເມືອງບັງບົດແກ້ງຫຼີ່ຜີ/เมืองบังบดแก้งหลี่ผี"
                          width="1020"
                          height="574"
                          src="https://www.youtube.com/embed/C8eI6LqIv2s?feature=oembed"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b
                        ><span class="section-title-main">ຕໍານານ ໜອງເງືອກ</span
                        ><b></b>
                      </h3>
                    </div>

                    <div class="video video-fit mb" style="padding-top: 56.25%">
                      <p>
                        <iframe
                          title="ຕໍານານ ໜອງເງືອກ | ตำนาน หนองเงือก EP.6 | UE4 Short Film"
                          width="1020"
                          height="574"
                          src="https://www.youtube.com/embed/C4AhnwC8680?feature=oembed"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-272505674">
                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <h3 style="text-align: center">ນິທານ</h3>
                    <div class="video video-fit mb" style="padding-top: 56.25%">
                      <p>
                        <iframe
                          title="ตำนานน้ำมันพราย | ตำนานไดอารี่ EP.9"
                          width="1020"
                          height="574"
                          src="https://www.youtube.com/embed/_z749hiKubE?feature=oembed"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <h3 style="text-align: center">ນິທານ</h3>
                    <div class="video video-fit mb" style="padding-top: 56.25%">
                      <p>
                        <iframe
                          title="ອາຈານເພັດ S1:E1 ຕອນ ດົງກ່ອງກ່ອຍ | อาจารย์เพชร: ตอน ดงกองกอย | UE4 Short Film"
                          width="1020"
                          height="574"
                          src="https://www.youtube.com/embed/JTkFolsU1_Q?feature=oembed"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-1010163145">
                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div class="video video-fit mb" style="padding-top: 56.25%">
                      <p>
                        <iframe
                          title="ศาลาพักข้างสุสาน | หลอนไดอารี่ EP.104"
                          width="1020"
                          height="574"
                          src="https://www.youtube.com/embed/dKv6S4KodSI?feature=oembed"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div class="video video-fit mb" style="padding-top: 56.25%">
                      <p>
                        <iframe
                          title="หลวงปู่มั่น ภูริทัตโต ปราบผีหลวง | ดาร์กสตอรี่ EP.14 | เล่าเรื่องผี 3D"
                          width="1020"
                          height="574"
                          src="https://www.youtube.com/embed/ne8nJo8UEqo?feature=oembed"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-1806189360">
                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b
                        ><span class="section-title-main">ຄອນເທັນໂດນໃຈ</span
                        ><b></b>
                      </h3>
                    </div>

                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_1224103405"
                    >
                      <div class="img-inner dark">
                        <img
                          width="711"
                          height="800"
                          src="../wp-content/uploads/2021/11/2.3-711x800.jpg"
                          class="attachment-large size-large"
                          alt=""
                          sizes="(max-width: 711px) 100vw, 711px"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b
                        ><span class="section-title-main"
                          >ຄອນເທັນໃຫ້ກຳລັງໃຈ</span
                        ><b></b>
                      </h3>
                    </div>

                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_1187890763"
                    >
                      <div class="img-inner dark">
                        <img
                          width="711"
                          height="800"
                          src="../wp-content/uploads/2021/11/1.6-711x800.jpg"
                          class="attachment-large size-large"
                          alt=""
                          sizes="(max-width: 711px) 100vw, 711px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-383319198">
                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_822893468"
                    >
                      <div class="img-inner dark">
                        <img
                          width="711"
                          height="800"
                          src="../wp-content/uploads/2021/11/1.7-711x800.jpg"
                          class="attachment-large size-large"
                          alt=""
                          sizes="(max-width: 711px) 100vw, 711px"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_1115533052"
                    >
                      <div class="img-inner dark">
                        <img
                          width="711"
                          height="800"
                          src="../wp-content/uploads/2021/11/2.1-711x800.jpg"
                          class="attachment-large size-large"
                          alt=""
                          sizes="(max-width: 711px) 100vw, 711px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-93775167">
                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_523245030"
                    >
                      <div class="img-inner dark">
                        <img
                          width="711"
                          height="800"
                          src="../wp-content/uploads/2021/10/18-1-711x800.jpg"
                          class="attachment-large size-large"
                          alt=""
                          sizes="(max-width: 711px) 100vw, 711px"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_902742444"
                    >
                      <div class="img-inner dark">
                        <img
                          width="711"
                          height="800"
                          src="../wp-content/uploads/2021/10/436-711x800.jpg"
                          class="attachment-large size-large"
                          alt=""
                          sizes="(max-width: 711px) 100vw, 711px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-1364726222">
                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <h3 style="text-align: center">ປະຕິທິນ ເດືອນ ພະຈິກ 2021</h3>
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_539902348"
                    >
                      <div class="img-inner dark">
                        <img
                          width="711"
                          height="800"
                          src="../wp-content/uploads/2021/11/Untitled-1-711x800.jpg"
                          class="attachment-large size-large"
                          alt=""
                          sizes="(max-width: 711px) 100vw, 711px"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <h3 style="text-align: center">ລາຄານ້ຳມັນ</h3>
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_1346103323"
                    >
                      <div class="img-inner dark">
                        <img
                          width="711"
                          height="800"
                          src="../wp-content/uploads/2021/11/ຄ່ານ້ຳມັນ10-711x800.jpg"
                          class="attachment-large size-large"
                          alt=""
                          sizes="(max-width: 711px) 100vw, 711px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-1016057748">
                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <h3 style="text-align: center">ຄຸນປະໂຫຍດຕົ້ນໄມ້</h3>
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_1417074723"
                    >
                      <div class="img-inner dark">
                        <img
                          width="711"
                          height="800"
                          src="../wp-content/uploads/2021/07/1-18-711x800.jpg"
                          class="attachment-large size-large"
                          alt=""
                          sizes="(max-width: 711px) 100vw, 711px"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <h3 style="text-align: center">ແຈກຟຣີສະຕິກເກີ້ລາຍ</h3>
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_1257351413"
                    >
                      <a
                        class=""
                        href="https://www.facebook.com/dLaolottery/photos/4858165067544031/"
                      >
                        <div class="img-inner dark">
                          <img
                            width="711"
                            height="800"
                            src="../wp-content/uploads/2021/07/ສະຕິກເກີ້1-7-711x800.jpg"
                            class="attachment-large size-large"
                            alt=""
                            sizes="(max-width: 711px) 100vw, 711px"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-1468932052">
                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b
                        ><span class="section-title-main"
                          >ລາຄາຄຳ 03/11/2021 10:00</span
                        ><b></b>
                      </h3>
                    </div>

                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_42869771"
                    >
                      <div class="img-inner dark">
                        <img
                          width="499"
                          height="800"
                          src="../wp-content/uploads/2021/11/250329915_3097835590542064_7711569247074721060_n-499x800.jpg"
                          class="attachment-large size-large"
                          alt=""
                          sizes="(max-width: 499px) 100vw, 499px"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b
                        ><span class="section-title-main"
                          >ເລດເງິນ ປະຈຳວັນທີ 03/11/2021</span
                        ><b></b>
                      </h3>
                    </div>

                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_498716814"
                    >
                      <div class="img-inner dark">
                        <img
                          width="510"
                          height="720"
                          src="../wp-content/uploads/2021/11/250299759_1892264114278976_2283485886262478192_n.jpg"
                          class="attachment-large size-large"
                          alt=""
                          sizes="(max-width: 510px) 100vw, 510px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-1415219562">
                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <h3 style="text-align: center">
                      ຂາຍບັດເຕີມເງິນທຸກເຄືອຂ່າຍ
                    </h3>
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_728891839"
                    >
                      <a
                        class=""
                        href="https://api.whatsapp.com/send?phone=+8562058895533"
                      >
                        <div class="img-inner dark">
                          <img
                            width="722"
                            height="800"
                            src="../wp-content/uploads/2021/07/99-2-722x800.jpg"
                            class="attachment-large size-large"
                            alt=""
                            sizes="(max-width: 722px) 100vw, 722px"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <h3 style="text-align: center">ຊື້ເລກກັບຫວຍອິນຊີ</h3>
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_168909340"
                    >
                      <a
                        class=""
                        href="https://api.whatsapp.com/send?phone=+8562059605899"
                      >
                        <div class="img-inner dark">
                          <img
                            width="711"
                            height="800"
                            src="../wp-content/uploads/2021/09/96666333-711x800.jpg"
                            class="attachment-large size-large"
                            alt=""
                            sizes="(max-width: 711px) 100vw, 711px"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-1228297027">
                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b
                        ><span class="section-title-main"
                          >ຕິດຕາມສະຖານະການ ໂຄວິດ-19</span
                        ><b></b>
                      </h3>
                    </div>

                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_154982338"
                    >
                      <div class="img-inner dark">
                        <img
                          width="711"
                          height="800"
                          src="../wp-content/uploads/2021/11/3-11-21-1-1-1-711x800.jpg"
                          class="attachment-large size-large"
                          alt=""
                          sizes="(max-width: 711px) 100vw, 711px"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b
                        ><span class="section-title-main"
                          >ຕິດຕາມສະຖານະການໂຄວິດ-19 ໃນທົ່ວໂລກ</span
                        ><b></b>
                      </h3>
                    </div>

                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_130992812"
                    >
                      <div class="img-inner dark">
                        <img
                          width="711"
                          height="800"
                          src="../wp-content/uploads/2021/10/COVID-19-ອາຊຽນ.psd4-Recovered-1-711x800.jpg"
                          class="attachment-large size-large"
                          alt=""
                          sizes="(max-width: 711px) 100vw, 711px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-70682235">
                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b
                        ><span class="section-title-main"
                          >ປະຫັວດຜູ້ຕິດເຊື້ອໃໝ່</span
                        ><b></b>
                      </h3>
                    </div>

                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_1579032075"
                    >
                      <div class="img-inner dark">
                        <img
                          width="711"
                          height="800"
                          src="../wp-content/uploads/2021/09/ຊຸມຊົນ-711x800.jpg"
                          class="attachment-large size-large"
                          alt=""
                          sizes="(max-width: 711px) 100vw, 711px"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div class="container section-title-container">
                      <h3 class="section-title section-title-center">
                        <b></b
                        ><span class="section-title-main"
                          >ບ້ານແດງ ນະຄອນຫລວງວຽງຈັນ</span
                        ><b></b>
                      </h3>
                    </div>

                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_497188135"
                    >
                      <div class="img-inner dark">
                        <img
                          width="711"
                          height="800"
                          src="../wp-content/uploads/2021/09/20-09-2021-711x800.jpg"
                          class="attachment-large size-large"
                          alt=""
                          sizes="(max-width: 711px) 100vw, 711px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="row-504878861">
                <div class="col medium-3 small-6 large-3">
                  <div class="col-inner">
                    <h3 style="text-align: center">ແວ່ເຮົາ ຂາຍເຄື່ອງອອນລາຍ</h3>
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_1712783211"
                    >
                      <a class="" href="https://www.facebook.com/HelloBabyBobo">
                        <div class="img-inner dark">
                          <img
                            width="800"
                            height="800"
                            src="../wp-content/uploads/2021/07/Untitled-1-2-800x800.png"
                            class="attachment-large size-large"
                            alt=""
                            sizes="(max-width: 800px) 100vw, 800px"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col medium-3 small-6 large-3">
                  <div class="col-inner">
                    <h3 style="text-align: center">ອອນຊອນ ຂາຍເຄື່ອງອອນລາຍ</h3>
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_910079239"
                    >
                      <a class="" href="https://www.facebook.com/onxon.online">
                        <div class="img-inner dark">
                          <img
                            width="800"
                            height="800"
                            src="../wp-content/uploads/2021/07/2-800x800.png"
                            class="attachment-large size-large"
                            alt=""
                            sizes="(max-width: 800px) 100vw, 800px"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col medium-3 small-6 large-3">
                  <div class="col-inner">
                    <h3 style="text-align: center">ອິນຊີມາດ ຂາຍເຄື່ອງອອນລາຍ</h3>
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_1553667554"
                    >
                      <a class="" href="https://www.facebook.com/inseesmart">
                        <div class="img-inner dark">
                          <img
                            width="800"
                            height="800"
                            src="../wp-content/uploads/2021/07/3-800x800.png"
                            class="attachment-large size-large"
                            alt=""
                            sizes="(max-width: 800px) 100vw, 800px"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col medium-3 small-6 large-3">
                  <div class="col-inner">
                    <h3 style="text-align: center">ຮ້ານອາຫານ ວິນລາທາດຫລວງ</h3>
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_2056555394"
                    >
                      <a
                        class=""
                        href="https://www.facebook.com/ຮ້ານອາຫານ-ວິນລາທາດຫຼວງ-Villa-Thatluang-Restaurant-102233104824023"
                      >
                        <div class="img-inner dark">
                          <img
                            width="800"
                            height="800"
                            src="../wp-content/uploads/2021/07/5-800x800.png"
                            class="attachment-large size-large"
                            alt=""
                            sizes="(max-width: 800px) 100vw, 800px"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container section-title-container">
                <h3 class="section-title section-title-normal">
                  <b></b><span class="section-title-main">ຂ່າວ ແລະ ບົດຄວາມ</span
                  ><b></b>
                </h3>
              </div>

              <div
                class="
                  row
                  large-columns-3
                  medium-columns-1
                  small-columns-1
                  slider
                  row-slider
                  slider-nav-reveal slider-nav-push
                "
                data-flickity-options='{"imagesLoaded": true, "groupCells": "100%", "dragThreshold" : 5, "cellAlign": "left","wrapAround": true,"prevNextButtons": true,"percentPosition": true,"pageDots": false, "rightToLeft": false, "autoPlay" : false}'
              >
                <div class="col post-item">
                  <div class="col-inner">
                    <a
                      href="%e0%ba%aa-%e0%ba%9b-%e0%ba%9b-%e0%ba%88%e0%ba%b5%e0%ba%99-%e0%ba%a5%e0%bb%8b%e0%ba%ad%e0%ba%81%e0%ba%94%e0%ba%b2%e0%ba%a7-%e0%ba%8a%e0%ba%bd%e0%ba%87%e0%bb%84%e0%ba%ae-%e0%ba%94%e0%ba%b5%e0%ba%aa/index.html"
                      class="plain"
                    >
                      <div
                        class="
                          box box-normal box-text-bottom box-blog-post
                          has-hover
                        "
                      >
                        <div class="box-image">
                          <div class="image-cover" style="padding-top: 56.25%">
                            <img
                              width="711"
                              height="400"
                              src="../wp-content/uploads/2021/11/ດິສນີ້-711x400.jpg"
                              class="
                                attachment-medium
                                size-medium
                                wp-post-image
                              "
                              alt=""
                              sizes="(max-width: 711px) 100vw, 711px"
                            />
                          </div>
                        </div>
                        <div class="box-text text-center">
                          <div class="box-text-inner blog-post-inner">
                            <h5 class="post-title is-large">
                              ສ ປ ປ ຈີນ ລ໋ອກດາວ ຊຽງໄຮ ດີສນີ້ແລນ 1 ຄືນ ຫຼັງພົບວ່າ
                              ມີຜູ້ຕິດເຊື້ອໂຄວິດ-19 1 ຄົນ
                            </h5>
                            <div class="is-divider"></div>
                            <p class="from_the_blog_excerpt">
                              ສ ປ ປ ຈີນ ໄດ້ລ໋ອກດາວ ແລະ ກັກຕົວນັກທ່ອງທ່ຽວ
                              ຫຼາຍກວ່າ 34,000 ຄົນ ໃນສວນສະນຸກຊຽງໄຮດິສນີ້ເເລນ
                              ຫຼັງພົບຜູ້ຕິດເຊື້ອໂຄວິດ 1 ກໍລະນີ,
                              ມີປະຫວັດການເດີນທາງແມ່ນເຄີຍໄປທ່ຽວຊົມສວນສະນຸກ [...]
                            </p>
                          </div>
                        </div>
                        <div class="badge absolute top post-date badge-outline">
                          <div class="badge-inner">
                            <span class="post-date-day">03</span><br />
                            <span class="post-date-month is-xsmall">Nov</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col post-item">
                  <div class="col-inner">
                    <a
                      href="%e0%ba%95%e0%ba%b4%e0%ba%94%e0%bb%80%e0%ba%8a%e0%ba%b7%e0%bb%89%e0%ba%ad%e0%bb%80%e0%ba%9e%e0%ba%b5%e0%bb%88%e0%ba%a1%e0%ba%82%e0%ba%b6%e0%bb%89%e0%ba%99%e0%bb%80%e0%ba%96%e0%ba%b4%e0%ba%87-1062/index.html"
                      class="plain"
                    >
                      <div
                        class="
                          box box-normal box-text-bottom box-blog-post
                          has-hover
                        "
                      >
                        <div class="box-image">
                          <div class="image-cover" style="padding-top: 56.25%">
                            <img
                              width="711"
                              height="400"
                              src="../wp-content/uploads/2021/11/covid-3-11-21-1-711x400.jpg"
                              class="
                                attachment-medium
                                size-medium
                                wp-post-image
                              "
                              alt=""
                              sizes="(max-width: 711px) 100vw, 711px"
                            />
                          </div>
                        </div>
                        <div class="box-text text-center">
                          <div class="box-text-inner blog-post-inner">
                            <h5 class="post-title is-large">
                              ຕິດເຊື້ອເພີ່ມຂຶ້ນເຖິງ 1,062 ກໍລະນີິ ທົ່ວປະເທດ
                            </h5>
                            <div class="is-divider"></div>
                            <p class="from_the_blog_excerpt">
                              ໃນວັນທີ່ 03 ພະຈິກ 2021, ທົ່ວປະເທດລາວເຮົາ
                              ມີປະຊາຊົນຕິດເຊື້ອເພີ່ມຂຶ້ນເຖິງ 1,062 ກໍລະນີ
                              ເປັນຍອດຄົນທີ່ຕິດເຊື້ອທີ່ຫຼາຍພໍສົມຄວນຖ້າທຽບໃສ່ໃນມື້ທີ່ຜ່ານໆມາ
                              ເປັນຍອດທີ່ໜ້າເປັນຫ່ວງຫຼາຍ ແລະ ເສຍຊີວິດເພີ່ມອີກ 3
                              ກໍລະນີ. [...]
                            </p>
                          </div>
                        </div>
                        <div class="badge absolute top post-date badge-outline">
                          <div class="badge-inner">
                            <span class="post-date-day">03</span><br />
                            <span class="post-date-month is-xsmall">Nov</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col post-item">
                  <div class="col-inner">
                    <a
                      href="%e0%ba%96%e0%ba%b7%e0%ba%81%e0%bb%81%e0%ba%97%e0%bb%89-%e0%ba%88%e0%bb%88%e0%ba%b2%e0%ba%8d%e0%ba%88%e0%ba%b4%e0%ba%87-%e0%ba%9a%e0%bb%8d%e0%bb%88%e0%bb%84%e0%ba%94%e0%bb%89%e0%ba%aa%e0%bb%89/index.html"
                      class="plain"
                    >
                      <div
                        class="
                          box box-normal box-text-bottom box-blog-post
                          has-hover
                        "
                      >
                        <div class="box-image">
                          <div class="image-cover" style="padding-top: 56.25%">
                            <img
                              width="711"
                              height="400"
                              src="../wp-content/uploads/2021/11/5555-711x400.jpg"
                              class="
                                attachment-medium
                                size-medium
                                wp-post-image
                              "
                              alt=""
                              sizes="(max-width: 711px) 100vw, 711px"
                            />
                          </div>
                        </div>
                        <div class="box-text text-center">
                          <div class="box-text-inner blog-post-inner">
                            <h5 class="post-title is-large">
                              ຖືກແທ້ ຈ່າຍຈິງ! ບໍ່ໄດ້ສ້າງພາບ!
                              ລວມຮູບພາບຜູ້ໂຊກດີຈາກ ຫວຍອິນຊີສາຂາ3
                            </h5>
                            <div class="is-divider"></div>
                            <p class="from_the_blog_excerpt">
                              ຂໍສະແດງຄວາມຍິນດີນຳທຸກໆທ່ານ ທີ່ໄດ້ໂຊກໄດ້ໄຊ ຈາກ
                              ຫວຍອິນຊີ ນະຄອນຫຼວງເລກ 3
                              ຄົນຕໍ່ໄປຂໍໃຫ້ເປັນທ່ານທີ່ຖືກເລກ ລາງວັນໃຫຍ່ 😃
                              ຈຳໜ່າຍເລກ 6ໂຕ 5ໂຕ 4ໂຕ 3ໂຕ 2ໂຕ [...]
                            </p>
                          </div>
                        </div>
                        <div class="badge absolute top post-date badge-outline">
                          <div class="badge-inner">
                            <span class="post-date-day">01</span><br />
                            <span class="post-date-month is-xsmall">Nov</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col post-item">
                  <div class="col-inner">
                    <a
                      href="%e0%bb%82%e0%ba%84%e0%ba%a7%e0%ba%b4%e0%ba%94-19-%e0%bb%83%e0%ba%99-%e0%ba%aa%e0%ba%9b%e0%ba%9b%e0%ba%a5%e0%ba%b2%e0%ba%a7-%e0%ba%8d%e0%ba%b1%e0%ba%87%e0%ba%9a%e0%bb%8d%e0%bb%88%e0%ba%ab%e0%ba%a5/index.html"
                      class="plain"
                    >
                      <div
                        class="
                          box box-normal box-text-bottom box-blog-post
                          has-hover
                        "
                      >
                        <div class="box-image">
                          <div class="image-cover" style="padding-top: 56.25%">
                            <img
                              width="711"
                              height="400"
                              src="../wp-content/uploads/2021/11/COVID261021-1-2-711x400.jpg"
                              class="
                                attachment-medium
                                size-medium
                                wp-post-image
                              "
                              alt=""
                              sizes="(max-width: 711px) 100vw, 711px"
                            />
                          </div>
                        </div>
                        <div class="box-text text-center">
                          <div class="box-text-inner blog-post-inner">
                            <h5 class="post-title is-large">
                              ໂຄວິດ-19 ໃນ ສປປລາວ
                              ຍັງບໍ່ຫລຸດໜ້ອຍຖອຍລົງແຕ່ຢ່າງໃດເລີຍ
                            </h5>
                            <div class="is-divider"></div>
                            <p class="from_the_blog_excerpt">
                              ອັດຕາການຕິດເຊື້ອ ໂຄວິດ-19 ວັນທີ່ 01 ພະຈິິກ 2021
                              ຕິດເຊື້ອໃຫມ່ +685 ກໍລະນີ, ຕິດເຊື້ອລວມທັງໝົ້ດ
                              40,956 ກໍລະນີ, ປິ່ນປົວດີອອກໂຮງໝໍແມ່ນ 219 ກໍລະນີ
                              [...]
                            </p>
                          </div>
                        </div>
                        <div class="badge absolute top post-date badge-outline">
                          <div class="badge-inner">
                            <span class="post-date-day">01</span><br />
                            <span class="post-date-month is-xsmall">Nov</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col post-item">
                  <div class="col-inner">
                    <a
                      href="%e0%ba%a1%e0%ba%b2%e0%ba%ae%e0%ba%b9%e0%bb%89%e0%ba%88%e0%ba%b1%e0%ba%81%e0%ba%81%e0%ba%b1%e0%ba%9a-metaverse/index.html"
                      class="plain"
                    >
                      <div
                        class="
                          box box-normal box-text-bottom box-blog-post
                          has-hover
                        "
                      >
                        <div class="box-image">
                          <div class="image-cover" style="padding-top: 56.25%">
                            <img
                              width="711"
                              height="400"
                              src="../wp-content/uploads/2021/10/WEBSITE-LOGO-copy-1-711x400.jpg"
                              class="
                                attachment-medium
                                size-medium
                                wp-post-image
                              "
                              alt=""
                              sizes="(max-width: 711px) 100vw, 711px"
                            />
                          </div>
                        </div>
                        <div class="box-text text-center">
                          <div class="box-text-inner blog-post-inner">
                            <h5 class="post-title is-large">
                              ມາຮູ້ຈັກກັບ &#8220;Metaverse&#8221;
                              ໂອກາດເຕີບໂຕມະຫາສານ ຂອງ Facebook
                            </h5>
                            <div class="is-divider"></div>
                            <p class="from_the_blog_excerpt">
                              ສະບັບຫຍໍ້ ນັກລົງທຶນໃຫ້ຄວາມສົນໃຈກັບເປົ້າໝາຍ ຂອງ
                              Mark Zuckerberg ທີ່ຕ້ອງການປ່ຽນ Facebook ຈາກ Social
                              Media Company ໄປສູ່ Metaverse Company. Metaverse
                              [...]
                            </p>
                          </div>
                        </div>
                        <div class="badge absolute top post-date badge-outline">
                          <div class="badge-inner">
                            <span class="post-date-day">29</span><br />
                            <span class="post-date-month is-xsmall">Oct</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col post-item">
                  <div class="col-inner">
                    <a href="8309-2/index.html" class="plain">
                      <div
                        class="
                          box box-normal box-text-bottom box-blog-post
                          has-hover
                        "
                      >
                        <div class="box-image">
                          <div class="image-cover" style="padding-top: 56.25%">
                            <img
                              width="711"
                              height="400"
                              src="../wp-content/uploads/2021/10/COVID261021-1-711x400.jpg"
                              class="
                                attachment-medium
                                size-medium
                                wp-post-image
                              "
                              alt=""
                              sizes="(max-width: 711px) 100vw, 711px"
                            />
                          </div>
                        </div>
                        <div class="box-text text-center">
                          <div class="box-text-inner blog-post-inner">
                            <h5 class="post-title is-large">
                              ເສຍຊີວິດໃນລາວ ວັນທີ 29/10/2021
                            </h5>
                            <div class="is-divider"></div>
                            <p class="from_the_blog_excerpt">
                              1. ເພດຊາຍ ອາຍຸ 42 ປີ ອາຊີບ ປະຊາຊົນ ບ້ານສະກ້າ
                              ເມືອງໂພນໂຮງ ແຂວງ ວຽງຈັນ ມີພະຍາດປະຈຳຕົວ
                              ເປັນພະຍາດເລືອດ 2 ປີ [...]
                            </p>
                          </div>
                        </div>
                        <div class="badge absolute top post-date badge-outline">
                          <div class="badge-inner">
                            <span class="post-date-day">29</span><br />
                            <span class="post-date-month is-xsmall">Oct</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col post-item">
                  <div class="col-inner">
                    <a
                      href="%e0%ba%97%e0%ba%b5%e0%ba%a1%e0%ba%8a%e0%ba%b2%e0%ba%94%e0%ba%9d%e0%ba%a3%e0%ba%b1%e0%bb%88%e0%ba%87-10-%e0%ba%84%e0%ba%bb%e0%ba%99-%e0%bb%80%e0%ba%9b%e0%ba%b5%e0%ba%94%e0%ba%9a%e0%bb%89%e0%ba%b2/index.html"
                      class="plain"
                    >
                      <div
                        class="
                          box box-normal box-text-bottom box-blog-post
                          has-hover
                        "
                      >
                        <div class="box-image">
                          <div class="image-cover" style="padding-top: 56.25%">
                            <img
                              width="800"
                              height="400"
                              src="../wp-content/uploads/2021/09/240824218_4285599064854113_8017739547634714139_n-800x400.jpg"
                              class="
                                attachment-medium
                                size-medium
                                wp-post-image
                              "
                              alt=""
                              sizes="(max-width: 800px) 100vw, 800px"
                            />
                          </div>
                        </div>
                        <div class="box-text text-center">
                          <div class="box-text-inner blog-post-inner">
                            <h5 class="post-title is-large">
                              ທີມຊາດຝຣັ່ງ 10 ຄົນ ເປີດບ້ານສະເໝີກັບ ບັອສເນຍ
                              ຄັດເລືອກ ບານໂລກ 2022
                            </h5>
                            <div class="is-divider"></div>
                            <p class="from_the_blog_excerpt">[...]</p>
                          </div>
                        </div>
                        <div class="badge absolute top post-date badge-outline">
                          <div class="badge-inner">
                            <span class="post-date-day">03</span><br />
                            <span class="post-date-month is-xsmall">Sep</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col post-item">
                  <div class="col-inner">
                    <a
                      href="%e0%bb%81%e0%ba%9e%e0%ba%b1%e0%ba%94%e0%ba%97%e0%ba%a3%e0%ba%b4%e0%ba%81-%e0%bb%81%e0%ba%84%e0%ba%99%e0%ba%a5%e0%ba%b5%e0%bb%89-%e0%ba%8a%e0%ba%b0%e0%ba%99%e0%ba%b0%e0%bb%80%e0%ba%9e%e0%ba%ad%e0%ba%b1/index.html"
                      class="plain"
                    >
                      <div
                        class="
                          box box-normal box-text-bottom box-blog-post
                          has-hover
                        "
                      >
                        <div class="box-image">
                          <div class="image-cover" style="padding-top: 56.25%">
                            <img
                              width="320"
                              height="400"
                              src="../wp-content/uploads/2021/08/240779377_4275395475874472_1147969954718171706_n-320x400.jpg"
                              class="
                                attachment-medium
                                size-medium
                                wp-post-image
                              "
                              alt=""
                              sizes="(max-width: 320px) 100vw, 320px"
                            />
                          </div>
                        </div>
                        <div class="box-text text-center">
                          <div class="box-text-inner blog-post-inner">
                            <h5 class="post-title is-large">
                              ແພັດທຣິກ ແຄນລີ້ ຊະນະເພອັອຟ ເດຊອມໂບ ຄວ້າແຊ້ມ BMW
                              Championship 2021 ພ້ອມຄວ້າສິດໄປ Ryder Cup
                            </h5>
                            <div class="is-divider"></div>
                            <p class="from_the_blog_excerpt">[...]</p>
                          </div>
                        </div>
                        <div class="badge absolute top post-date badge-outline">
                          <div class="badge-inner">
                            <span class="post-date-day">31</span><br />
                            <span class="post-date-month is-xsmall">Aug</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="section dark" id="section_1878830939">
            <div class="bg section-bg fill bg-fill bg-loaded"></div>

            <div class="section-content relative">
              <div class="row" id="row-1875227545">
                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner" style="padding: 44px 0px 0px 0px">
                    <div class="video video-fit mb" style="padding-top: 56.25%">
                      <p>
                        <iframe
                          title="เลขเด็ด ຫວຍດີຈິຕ້ອນ ສະໝັກງ່າຍໆ ລາຍໄດ້ດີ ສະດວກສະບາຍ ມາເປັນຄອບຄົວດຽວກັນກັບພວກເຮົາເດີພີ້ນ້ອງ"
                          width="1020"
                          height="574"
                          src="https://www.youtube.com/embed/JcDAOcK4rdM?feature=oembed&amp;enablejsapi=1&amp;origin=https://laodl.com"
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <h3 style="text-align: left"></h3>
                    <h2 style="text-align: center">
                      <strong>ປະຫວັດບໍລິສັດ INSEE TRADING</strong>
                    </h2>
                    <h2 style="text-align: center">
                      <strong
                        ><span style="font-size: 80%"
                          >ຕົວແທນຈຳໜ່າຍ ຫວຍພັດທະນາຮ່ວມໃຈ ນະຄອນຫຼວງເລກ 3</span
                        ></strong
                      >
                    </h2>
                    <p style="text-align: center">
                      <span style="font-size: 80%">
                        ບໍລິສັດ ອິນຊີ ເທຣດດິ້ງ ຈຳກັດຜູ້ດຽວ ໄດ້ຮັບອານຸຍາດ
                        ຈາກກະຊວງການເງິນ ກໍ່ຄື ບໍລິສັດລັດ ວິສາຫະກິດ
                        ຫວຍພັດທະນາຮ່ວມໃຈ ແຫ່ງ ສ.ປ.ປ.ລາວ ໃຫ້ເປັນຕົວແທນຈຳໜ່າຍ
                        ຫວຍພັດທະນາຮ່ວມໃຈ ນະຄອນຫຼວງເລກ3, ເລີ່ມຈຳໜ່າຍ ຫວຍແບບຈົດຂາຍ
                        ນັບແຕ່ວັນທີ 17 ກໍລະກົດ 2003 ເປັນຕົ້ນມາ,
                        ຊຶ່ງມີສຳນັກງານໃຫ່ຍຕັ້ງຢູ່ ຖະໜົນ 23 ສິງຫາ, ບ້ານ ໜອງບອນ,
                        ເມືອງ ໄຊເສດຖາ, ນະຄອນຫຼວງເລກ3</span
                      ><br />
                      <span style="font-size: 80%">
                        ບໍລິສັດ ອິນຊີ ເທຣດດິ້ງ ຈຳກັດຜູ້ດຽວ ຕົວແທນຈຳໜ່າຍ
                        ຫວຍພັດທະນາຮ່ວມໃຈ ນະຄອນຫຼວງເລກ3,
                        ແມ່ນວິສາຫະກິດສ່ວນບຸກຄົນຊິ່ງແມ່ນ ບໍລິສັດລັດ ວິສາຫະກິດ
                        ຫວຍພັດທະນາຮ່ວມໃຈ ເປັນຜູ້ຊີ້ນຳໃນການຈັດຕັ້ງປະຕິບັດ
                        ດ້ານຫວຍພັດທະນາຮ່ວມໃຈ ພາຍໃຕ້ກົດໜາຍຂອງ ສ.ປ.ປ.ລາວ
                        ມີໜ້າທີ່ໃນການບໍລິການຈຳໜ່າຍຫວຍພັດທະນາຮ່ວມໃຈ ເລກ 2ໂຕ 3ໂຕ
                        ທາງຕົວແທນຈຳໜ່າຍມີສິດເຕັມສ່ວນໃນການດຳເນີນທຸລະກິດ.<br />
                        ທາງຕົວແທນຈຳໜ່າຍໄດ້ດຳເນີນການຂາຍຫວຍແບບພັດທະນາຂື້ນເລື້ອຍໆຢ່າງເຫັນໄດ້ຊັດເຈນເຊັ່ນ:</span
                      >
                    </p>
                    <ul>
                      <li>
                        <span style="font-size: 80%"
                          >ປີ 2003 ພັດທະນາຈາກການຈີກມາເປັນຈົດຂາຍ</span
                        >
                      </li>
                      <li style="text-align: left">
                        <span style="font-size: 80%"
                          >ປີ 2004 ໄດ້ມີພະລິດຕະພັນໃໝ່ອອກຈຳໜ່າຍກໍ່ຄື ຫວຍຂູດ ແລະ
                          ຈຳໜ່າຍໃບຫວຍສາຍພິເສດເລກ 5ໂຕ ໃນວັນສຳຄັນຕ່າງໆ</span
                        >
                      </li>
                      <li style="text-align: left">
                        <span style="font-size: 80%"
                          >ປີ 2007 ໄດ້ຈຳໜ່າຍຫວຍ 4ໂຕເພີ້ມໂຊກ</span
                        >
                      </li>
                      <li style="text-align: left">
                        <span style="font-size: 80%"
                          >ປີ 2008 ໄດ້ຈຳໜ່າຍຜະລິດຕະພັນຫວຍໂລໂຕ 5/32</span
                        >
                      </li>
                      <li style="text-align: left">
                        <span style="font-size: 80%"
                          >ປີ 2011
                          ພັດທະນາຈາກການຂາຍແບບຈົດມາເປັນຫວຍແບບດີຈິຕ້ອນ</span
                        >
                      </li>
                      <li style="text-align: left">
                        <span style="font-size: 80%"
                          >ປີ 2015 ໄດ້ຮັບອານຸຍາດຈຳໜ່າຍຫວຍດີຈິຕ້ອນ ເລກ 2ໂຕ 3ໂຕ
                          ທົ່ວປະເທດ</span
                        ><br />
                        <h6></h6>
                        <p>
                          ຫວຍດີຈິຕ້ອນ ສາຂາ 3 ອິນຊີ ຕົວແທນຈຳໜ່າຍນະຄອນຫຼວງ ເລກ3
                          ເປັນຕົວແທນຈຳໜ່າຍໄດ້ທົ່ວປະເທດ
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="section" id="section_1331412847">
            <div class="bg section-bg fill bg-fill bg-loaded"></div>

            <div class="section-content relative">
              <div
                class="img has-hover x md-x lg-x y md-y lg-y"
                id="image_1144323901"
              >
                <div class="img-inner dark">
                  <img
                    width="2560"
                    height="806"
                    src="../wp-content/uploads/2021/06/testimonel-scaled.jpg"
                    class="attachment-original size-original"
                    alt=""
                    sizes="(max-width: 2560px) 100vw, 2560px"
                  />
                </div>
              </div>
            </div>
          </section>

          <section class="section dark" id="section_655344146">
            <div class="bg section-bg fill bg-fill bg-loaded"></div>

            <div class="section-content relative">
              <div class="row align-center" id="row-330459826">
                <div class="col medium-10 small-12 large-10">
                  <div class="col-inner text-center">
                    <h2 class="uppercase"><b>ຕົວແທນຈຳໜ່າຍທົ່ວປະເທດ</b></h2>
                  </div>
                </div>
              </div>
              <div class="row" id="row-1786056185">
                <div class="col small-12 large-12">
                  <div class="col-inner">
                    <div class="slider-wrapper relative" id="slider-149028357">
                      <div
                        class="
                          slider
                          slider-nav-circle
                          slider-nav-large
                          slider-nav-light
                          slider-style-normal
                        "
                        data-flickity-options='{
            "cellAlign": "center",
            "imagesLoaded": true,
            "lazyLoad": 1,
            "freeScroll": false,
            "wrapAround": true,
            "autoPlay": 6000,
            "pauseAutoPlayOnHover" : true,
            "prevNextButtons": true,
            "contain" : true,
            "adaptiveHeight" : true,
            "dragThreshold" : 10,
            "percentPosition": true,
            "pageDots": true,
            "rightToLeft": false,
            "draggable": true,
            "selectedAttraction": 0.1,
            "parallax" : 0,
            "friction": 0.6        }'
                      >
                        <div
                          class="img has-hover x md-x lg-x y md-y lg-y"
                          id="image_599662668"
                        >
                          <div class="img-inner dark">
                            <img
                              width="3675"
                              height="2169"
                              src="../wp-content/uploads/2019/03/laodl_dealer_digital3.jpg"
                              class="attachment-original size-original"
                              alt=""
                              sizes="(max-width: 3675px) 100vw, 3675px"
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="section" id="section_170196860">
            <div class="bg section-bg fill bg-fill bg-loaded"></div>

            <div class="section-content relative">
              <div class="row align-center" id="row-566556277">
                <div class="col medium-10 small-12 large-10">
                  <div
                    class="col-inner text-center"
                    style="margin: 40px 0px -5px 0px"
                  >
                    <h2 class="uppercase">
                      <b>ວິດີໂອສອນວິທີການຂາຍ ແລະ ການນຳໃຊ້ອຸປະກອນຕ່າງໆ</b>
                    </h2>
                  </div>
                </div>
              </div>
              <div class="row" id="row-1722442075">
                <div class="col small-12 large-12">
                  <div class="col-inner">
                    <section
                      id="origincode_videogallery_content_1"
                      class="gallery-video-content"
                      data-gallery-video-id="1"
                      data-gallery-video-perpage="5"
                    >
                      <div
                        id="origincode_videogallery_container_1"
                        class="
                          origincode_videogallery_container
                          super-list
                          variable-sizes
                          clearfix
                          view-lightbox-gallery
                        "
                        data-show-center="off"
                      >
                        <div
                          id="origincode_videogallery_container_moving_1"
                          class="super-list variable-sizes clearfix"
                        >
                          <input type="hidden" class="pagenum" value="1" />
                          <input type="hidden" id="total" value="1" />
                          <div
                            data-id="1"
                            class="video-element_1 video-element"
                            tabindex="0"
                            data-symbol="ຫວຍດີຈິຕ້ອນ ສະໝັກງ່າຍໆ ລາຍໄດ້ດີ ສະດວກສະບາຍ ມາເປັນຄອບຄົວດຽວກັນກັບພວກເຮົາເດີພີ້ນ້ອງ"
                            data-category="alkaline-earth"
                          >
                            <div class="image-block_1">
                              <a
                                class="
                                  vyoutube
                                  origincode_videogallery_item
                                  group1
                                "
                                href="http://www.youtube.com/embed/JcDAOcK4rdM"
                                title="ຫວຍດີຈິຕ້ອນ ສະໝັກງ່າຍໆ ລາຍໄດ້ດີ ສະດວກສະບາຍ ມາເປັນຄອບຄົວດຽວກັນກັບພວກເຮົາເດີພີ້ນ້ອງ"
                                data-description="<p>ຫວຍດີຈິຕ້ອນ ສະໝັກງ່າຍໆ ລາຍໄດ້ດີ ສະດວກສະບາຍ ມາເປັນຄອບຄົວດຽວກັນກັບພວກເຮົາເດີພີ້ນ້ອງ</p>"
                                data-id="1"
                              >
                                <img
                                  src="../img.youtube.com/vi/JcDAOcK4rdM/mqdefault.jpg"
                                  alt="ຫວຍດີຈິຕ້ອນ ສະໝັກງ່າຍໆ ລາຍໄດ້ດີ ສະດວກສະບາຍ ມາເປັນຄອບຄົວດຽວກັນກັບພວກເຮົາເດີພີ້ນ້ອງ"
                                />
                                <div
                                  class="play-icon http://youtube-icon"
                                ></div>
                              </a>
                            </div>
                            <div class="title-block_1">
                              <a
                                href="https://origincode.co/downloads/video-gallery/"
                                target="_blank"
                              >
                                ຫວຍດີຈິຕ້ອນ ສະໝັກງ່າຍໆ ລາຍໄດ້ດີ ສະດວກສະບາຍ
                                ມາເປັນຄອບຄົວດຽວກັນກັບພວກເຮົາເດີພີ້ນ້ອງ
                              </a>
                            </div>
                          </div>
                          <div
                            data-id="2"
                            class="video-element_1 video-element"
                            tabindex="0"
                            data-symbol="ແນະນຳວິທີການ ແລະ ຂັ້ນຕອນການເຊື່ອມ Printer"
                            data-category="alkaline-earth"
                          >
                            <div class="image-block_1">
                              <a
                                class="
                                  vyoutube
                                  origincode_videogallery_item
                                  group1
                                "
                                href="http://www.youtube.com/embed/tLJ8fB_2o2Y"
                                title="ແນະນຳວິທີການ ແລະ ຂັ້ນຕອນການເຊື່ອມ Printer"
                                data-description="<p>ຫວຍດີຈິຕ້ອນ ສະໝັກງ່າຍໆ ລາຍໄດ້ດີ ສະດວກສະບາຍ ມາເປັນຄອບຄົວດຽວກັນກັບພວກເຮົາເດີພີ້ນ້ອງ.</p>"
                                data-id="2"
                              >
                                <img
                                  src="../img.youtube.com/vi/tLJ8fB_2o2Y/mqdefault.jpg"
                                  alt="ແນະນຳວິທີການ ແລະ ຂັ້ນຕອນການເຊື່ອມ Printer"
                                />
                                <div
                                  class="play-icon http://youtube-icon"
                                ></div>
                              </a>
                            </div>
                            <div class="title-block_1">
                              <a
                                href="https://origincode.co/downloads/video-gallery/"
                                target="_blank"
                              >
                                ແນະນຳວິທີການ ແລະ ຂັ້ນຕອນການເຊື່ອມ Printer
                              </a>
                            </div>
                          </div>
                          <div
                            data-id="3"
                            class="video-element_1 video-element"
                            tabindex="0"
                            data-symbol="ບໍລິການຈ່າຍເງິນລາງວັນທຸກສາຂາທົ່ວປະເທດ"
                            data-category="alkaline-earth"
                          >
                            <div class="image-block_1">
                              <a
                                class="
                                  vyoutube
                                  origincode_videogallery_item
                                  group1
                                "
                                href="http://www.youtube.com/embed/0qLJv5wg0LE"
                                title="ບໍລິການຈ່າຍເງິນລາງວັນທຸກສາຂາທົ່ວປະເທດ"
                                data-description="<h6>ບໍລິການຈ່າຍເງິນລາງວັນທຸກສາຂາທົ່ວປະເທດ Insee trading ຫວຍສາຂາ 3</p>"
                                data-id="3"
                              >
                                <img
                                  src="../img.youtube.com/vi/0qLJv5wg0LE/mqdefault.jpg"
                                  alt="ບໍລິການຈ່າຍເງິນລາງວັນທຸກສາຂາທົ່ວປະເທດ"
                                />
                                <div
                                  class="play-icon http://youtube-icon"
                                ></div>
                              </a>
                            </div>
                            <div class="title-block_1">
                              <a
                                href="https://origincode.co/downloads/video-gallery/"
                                target="_blank"
                              >
                                ບໍລິການຈ່າຍເງິນລາງວັນທຸກສາຂາທົ່ວປະເທດ
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="section dark" id="section_398681771">
            <div class="bg section-bg fill bg-fill bg-loaded"></div>

            <div class="section-content relative">
              <div class="row" id="row-1087394164">
                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <p>
                      <b>ສະໝັກ ເປັນຫົວໜ້າໜ່ວຍ </b
                      ><b>ແລະ ຄົນຂາຍເລກ ກັບເຮົາ ງ່າຍງ່າຍ</b>
                    </p>
                    <noscript class="ninja-forms-noscript-message">
                      Notice: JavaScript is required for this content.</noscript
                    >
                    <div
                      id="nf-form-2_1-cont"
                      class="nf-form-cont"
                      aria-live="polite"
                      aria-labelledby="nf-form-title-2_1"
                      aria-describedby="nf-form-errors-2_1"
                      role="form"
                    >
                      <div class="nf-loading-spinner"></div>
                    </div>
                    <!-- TODO: Move to Template File. -->
                  </div>
                </div>

                <div class="col medium-6 small-12 large-6">
                  <div class="col-inner">
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_210070846"
                    >
                      <div class="img-inner dark">
                        <img
                          width="638"
                          height="432"
                          src="../wp-content/uploads/2019/02/register.jpg"
                          class="attachment-original size-original"
                          alt=""
                          sizes="(max-width: 638px) 100vw, 638px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="section" id="section_934369606">
            <div class="bg section-bg fill bg-fill bg-loaded">
              <div class="section-bg-overlay absolute fill"></div>
            </div>

            <div class="section-content relative">
              <div class="row row-large align-middle" id="row-858974446">
                <div class="col small-12 large-12">
                  <div
                    class="col-inner text-center"
                    style="margin: 19px 0px -16px 0px"
                  >
                    <h1><strong>ໂຫລດສະຕິກເກີ້ເລີຍ</strong></h1>
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_1328984732"
                    >
                      <a
                        class=""
                        href="https://store.line.me/stickershop/product/1552096/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div class="img-inner dark">
                          <img
                            width="1020"
                            height="291"
                            src="../wp-content/uploads/2019/03/sticker_digital3-laodl-1400x400.jpg"
                            class="attachment-large size-large"
                            alt=""
                            sizes="(max-width: 1020px) 100vw, 1020px"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="section" id="section_1627645518">
            <div class="bg section-bg fill bg-fill bg-loaded"></div>

            <div class="section-content relative">
              <div
                id="gap-1916758174"
                class="gap-element clearfix"
                style="display: block; height: auto"
              ></div>

              <div class="row" id="row-1036940500">
                <div class="col small-12 large-12">
                  <div class="col-inner">
                    <h2 style="text-align: center">
                      ເວັບໄຊທ໌ ກຸ່ມບໍລິສັດ ອິນຊີ
                    </h2>
                  </div>
                </div>
              </div>
              <div class="row" id="row-388564610">
                <div class="col medium-4 small-6 large-3">
                  <div class="col-inner text-center">
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_574056901"
                    >
                      <div class="img-inner dark">
                        <img
                          width="800"
                          height="800"
                          src="../wp-content/uploads/2019/10/inseeexpress-laodl-800x800.jpg"
                          class="attachment-large size-large"
                          alt=""
                          sizes="(max-width: 800px) 100vw, 800px"
                        />
                      </div>
                    </div>

                    <a
                      rel="noopener noreferrer"
                      href="https://www.inseex.com/"
                      target="_blank"
                      class="button primary is-underline"
                    >
                      <span>Insee Express</span>
                    </a>
                  </div>
                </div>

                <div class="col medium-4 small-6 large-3">
                  <div class="col-inner text-center">
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_1467393224"
                    >
                      <div class="img-inner dark">
                        <img
                          width="800"
                          height="800"
                          src="../wp-content/uploads/2019/10/inseegroup-laodl-800x800.jpg"
                          class="attachment-large size-large"
                          alt=""
                          sizes="(max-width: 800px) 100vw, 800px"
                        />
                      </div>
                    </div>

                    <a
                      rel="noopener noreferrer"
                      href="http://www.insee-group.com/"
                      target="_blank"
                      class="button primary is-underline"
                    >
                      <span>INSEEGROUP</span>
                    </a>
                  </div>
                </div>

                <div class="col medium-4 small-6 large-3">
                  <div class="col-inner text-center">
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_648392836"
                    >
                      <div class="img-inner dark">
                        <img
                          width="800"
                          height="800"
                          src="../wp-content/uploads/2019/10/onxon.com-laodl-800x800.jpg"
                          class="attachment-large size-large"
                          alt=""
                          sizes="(max-width: 800px) 100vw, 800px"
                        />
                      </div>
                    </div>

                    <a
                      rel="noopener noreferrer"
                      href="http://www.onxon.com/"
                      target="_blank"
                      class="button primary is-underline"
                    >
                      <span>ONXON</span>
                    </a>
                  </div>
                </div>

                <div class="col medium-4 small-6 large-3">
                  <div class="col-inner text-center">
                    <div
                      class="img has-hover x md-x lg-x y md-y lg-y"
                      id="image_1605474114"
                    >
                      <div class="img-inner dark">
                        <img
                          width="800"
                          height="800"
                          src="../wp-content/uploads/2019/10/inseetrading-laodl-800x800.jpg"
                          class="attachment-large size-large"
                          alt=""
                          sizes="(max-width: 800px) 100vw, 800px"
                        />
                      </div>
                    </div>

                    <a
                      rel="noopener noreferrer"
                      href="https://inseeinsurance.com/"
                      target="_blank"
                      class="button primary is-underline"
                    >
                      <span>inseeinsurance</span>
                    </a>
                  </div>
                </div>
              </div>
              <h2 style="text-align: center">ເວັບໄຊທ໌ ຫວຍທີ່ກ່ຽວຂ້ອງ</h2>
              <div class="row" id="row-186536232">
                <div class="col medium-4 small-6 large-3">
                  <div class="col-inner text-center">
                    <a
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/Laolottery-Live-277341752690795/"
                      target="_blank"
                      class="button primary is-underline"
                    >
                      <span>ເທັບບັນທຶກພາບ ຜົນການອອກຫວຍລັດຖະບານ</span>
                    </a>
                  </div>
                </div>

                <div class="col medium-4 small-6 large-3">
                  <div class="col-inner text-center">
                    <a
                      href="https://www.xoso2u.com/Member/GameResult?type=vn"
                      target="_self"
                      class="button primary is-underline lowercase"
                    >
                      <span>ຫວຍອາຊຽນ</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="row" id="row-2083371148">
                <div class="col small-12 large-12">
                  <div class="col-inner">
                    <div class="row" id="row-1537047813">
                      <div class="col small-12 large-12">
                        <div class="col-inner">
                          <p>
                            <iframe
                              src="https://www.google.com/maps/d/embed?mid=10Q-qLZ42d4K2bgqy2VLMBvlsz0c&amp;hl=th"
                              width="100%"
                              height="480"
                            ></iframe>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>

      <footer id="footer" class="footer-wrapper">
        <!-- FOOTER 1 -->

        <!-- FOOTER 2 -->
        <div class="footer-widgets footer footer-2 dark">
          <div class="row dark large-columns-4 mb-0">
            <div id="block_widget-2" class="col pb-0 widget block_widget">
              <div class="row" id="row-956557943">
                <div class="col small-12 large-12">
                  <div class="col-inner">
                    <h2 style="text-align: left">
                      <strong>ຕິດຕໍ່ເຮົາ</strong>
                    </h2>
                  </div>
                </div>
              </div>
              <div class="row" id="row-445676869">
                <div class="col small-12 large-12">
                  <div class="col-inner" style="margin: -24px 0px -38px 0px">
                    <p>
                      ທ່ານໃດສົນໃຈຢາກເປັນຕົວແທນຈໍາໜ່າຍ ຫວຍພັດທະນາຮ່ວມໃຈ
                      ສາມາດຕິດຕໍ່ໄດ້ທາງ<br />ບໍລິສັດ ອິນຊີ ເທຣດດີ້ງ ຈໍາກັດຜູ້ດຽວ
                      ຕົວແທນຈໍາໜ່າຍຫວຍພັດທະນາຮ່ວມໃຈເລກ 3
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div id="block_widget-4" class="col pb-0 widget block_widget">
              <div class="row" id="row-861929770">
                <div class="col small-12 large-12">
                  <div class="col-inner">
                    ທີ່ຢູ່:ຖະໜົນ 23 ສິງຫາ,ບ້ານ ໜອງບອນ,ເມືອງ ໄຊເສດຖາ,
                    ນະຄອນຫຼວງວຽງຈັນ, ໂທລະສັບ: 021 41 42 43. ແຟັກ: 021 450 081.
                    Email:
                    <a
                      href="cdn-cgi/l/email-protection.html"
                      class="__cf_email__"
                      data-cfemail="e980878f86a9ada58886a5869d9d8c9b90c78a8684"
                      >[email&#160;protected]</a
                    >
                    Email:
                    <a
                      href="cdn-cgi/l/email-protection.html"
                      class="__cf_email__"
                      data-cfemail="0e6a626f613c3e3f3c4e66617a636f6762206d6163"
                      >[email&#160;protected]</a
                    >
                  </div>
                </div>
              </div>
            </div>

            <div id="block_widget-5" class="col pb-0 widget block_widget">
              <div class="row" id="row-2027475205">
                <div class="col small-12 large-12">
                  <div class="col-inner">
                    <p>ຕິດຕາມຜົນການອອກຫວຍໄດ້ກ່ອນໝູ່ ຕິດຕໍ່ເຮົາງ່າຍໆ</p>
                    <div class="row align-center" id="row-1329076127">
                      <div class="col small-12 large-12">
                        <div
                          class="col-inner"
                          style="padding: 0 0px 0px 0px; margin: 0 0px 0px 0px"
                        >
                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_534441232"
                          >
                            <a class="" href="https://line.me/R/ti/p/@qgr6804k">
                              <div class="img-inner dark">
                                <img
                                  width="312"
                                  height="69"
                                  src="../wp-content/uploads/2021/06/line.png"
                                  class="attachment-large size-large"
                                  alt=""
                                />
                              </div>
                            </a>
                          </div>

                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_1861388853"
                          >
                            <a
                              class=""
                              href="https://www.messenger.com/t/598578720169375/?ref=pages_you_manage&amp;messaging_source=source%3Apages%3Amessage_shortlink"
                            >
                              <div class="img-inner dark">
                                <img
                                  width="312"
                                  height="69"
                                  src="../wp-content/uploads/2021/06/Mess.png"
                                  class="attachment-large size-large"
                                  alt=""
                                />
                              </div>
                            </a>
                          </div>

                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_1365031943"
                          >
                            <a
                              class=""
                              href="https://api.whatsapp.com/send?phone=+8562058895533"
                            >
                              <div class="img-inner dark">
                                <img
                                  width="312"
                                  height="69"
                                  src="../wp-content/uploads/2021/06/whatapp.png"
                                  class="attachment-large size-large"
                                  alt=""
                                />
                              </div>
                            </a>
                          </div>

                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_359071161"
                          >
                            <a
                              class=""
                              href="https://www.youtube.com/channel/UC_hY7PHas5MHgf-T_d0I68w"
                            >
                              <div class="img-inner dark">
                                <img
                                  width="312"
                                  height="69"
                                  src="../wp-content/uploads/2021/06/youtube.png"
                                  class="attachment-large size-large"
                                  alt=""
                                />
                              </div>
                            </a>
                          </div>

                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_1380705425"
                          >
                            <a class="" href="https://vt.tiktok.com/ZSJqUySFk/">
                              <div class="img-inner dark">
                                <img
                                  width="312"
                                  height="69"
                                  src="../wp-content/uploads/2021/07/Tiktok.png"
                                  class="attachment-large size-large"
                                  alt=""
                                />
                              </div>
                            </a>
                          </div>

                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_1653903130"
                          >
                            <a class="" href="tel:021414243">
                              <div class="img-inner dark">
                                <img
                                  width="312"
                                  height="69"
                                  src="../wp-content/uploads/2021/06/CALL.png"
                                  class="attachment-large size-large"
                                  alt=""
                                />
                              </div>
                            </a>
                          </div>

                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_1294688389"
                          >
                            <a
                              class=""
                              href="https://m.me/dLaolottery/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div
                                class="img-inner dark"
                                style="margin: 0 0px 0px 0px"
                              ></div>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div class="col small-12 large-12">
                        <div
                          class="col-inner"
                          style="padding: 0 0px 0px 0px; margin: 0 0px 0px 0px"
                        >
                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_984856891"
                          >
                            <a
                              class=""
                              href="tel:02059605899"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div
                                class="img-inner dark"
                                style="margin: 0 0px 0px 0px"
                              ></div>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div class="col small-12 large-12">
                        <div class="col-inner">
                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_583595869"
                          >
                            <a
                              class=""
                              href="tel:021414243"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div
                                class="img-inner dark"
                                style="margin: 0 0px 0px 0px"
                              ></div>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div class="col small-12 large-12">
                        <div class="col-inner">
                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_147417259"
                          >
                            <a class="" href="#">
                              <div
                                class="img-inner dark"
                                style="margin: 0 0px 0px 0px"
                              ></div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="block_widget-3" class="col pb-0 widget block_widget">
              <h3><strong>ສົນໃຈເປັນຕົວແທນຈໍາໜ່າຍ</strong></h3>
              <p>ເອກະສານປະກອບມີ</p>
              <ul>
                <li>ໃບຢັ້ງຢືນທີ່ຢູ່</li>
                <li>ໃບຮັບປະກັນ</li>
                <li>ກ໋ອບປີ້ບັດປະຈຳຕົວ</li>
                <li>ແຜນທີ່ບ້ານ</li>
                <li>ຮູບເຮືອນ</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="absolute-footer dark medium-text-center text-center">
          <div class="container clearfix">
            <div class="footer-primary pull-left">
              <div class="copyright-footer">
                Copyright 2021 © <strong>INSEE TRADING</strong>
              </div>
            </div>
          </div>
        </div>
        <a
          href="#top"
          class="
            back-to-top
            button
            icon
            invert
            plain
            fixed
            bottom
            z-1
            is-outline
            hide-for-medium
            circle
          "
          id="top-link"
          ><i class="icon-angle-up"></i
        ></a>
      </footer>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "Home",
  data() {
    return {
      baseURL:
        "https://opensheet.vercel.app/1gS9TveyBC2ae8Ns5jpThRAA6J2iAqaPwIrHpB10nfNI/Now",
      ListDigit: [],
      open_side_bar: false,
      windowTop: 0,
      on_scroll: false,
      on_resize: false,
      Digit6: "",
      Digit5: "",
      Digit4: "",
      Digit3: "",
      Digit2: "",
      Digit1: "",
      random_6: "",
      random_5: "",
      random_4: "",
      random_3: "",
      random_2: "",
      random_1: "",
      setting:"",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.onResize);
    this.Init_config();
    this.Init_Data();
    this.Render_setting();
    this.onResize();
    setInterval(this.waiting_digit, 200);
    setInterval(this.updateClock, 200);
    setInterval(this.Render_setting, 5000);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    Render_setting() {
      let annount_time = moment(this.setting.time_Announcement, 'HH:mm:ss').format("HH:mm")
      let close_time = moment(this.setting.close_time, 'HH:mm:ss').format("HH:mm")
      let current_time = moment(Date.now()).format("HH:mm")
      if(current_time == close_time){
          this.Init_config()
      }
      if(annount_time == current_time){
          this.Init_Data()
      }
    },
    updateClock() {
      String.prototype.replaceAt = function(index, replacement) {
        if (index >= this.length) {
            return this.valueOf();
        }
        return this.substring(0, index) + replacement + this.substring(index + 1);
      }
      let annount_time = moment(this.setting.time_Announcement, 'HH:mm:ss').format("HH:mm")
      let close_time = moment(this.setting.close_time, 'HH:mm:ss').format("HH:mm")
      let current_time = moment(Date.now()).format("HH:mm")
      var time = new Date()
      if(close_time == current_time){
        this.Init_config()
      }
      if(annount_time == current_time){
        let number_ = this.ListDigit.numberset + ""
        let digit6 = number_.charAt(0)
        let digit5 = number_.charAt(1)
        let digit4 = number_.charAt(2)
        let digit3 = number_.charAt(3)
        let digit2 = number_.charAt(4)
        let digit1 = number_.charAt(5)
        this.Digit6 = "XXXXXX";
        this.Digit5 = "XXXXX";
        this.Digit4 = "XXXX";
        this.Digit3 = "XXX";
        this.Digit2 = "XX";
        this.Digit1 = "X";
        if (time.getSeconds() >= 0 && time.getSeconds() <= 59) {
          this.Digit6 = this.Digit6.replaceAt(0, digit6)
        }
        if (time.getSeconds() >= 8 && time.getSeconds() <= 59) {
          this.Digit6 = this.Digit6.replaceAt(1, digit5)
          this.Digit5 = this.Digit5.replaceAt(0, digit5)
        }
        if (time.getSeconds() >= 16 && time.getSeconds() <= 59) {
          this.Digit6 = this.Digit6.replaceAt(2, digit4)
          this.Digit5 = this.Digit5.replaceAt(1, digit4)
          this.Digit4 = this.Digit4.replaceAt(0, digit4)
        }
        if (time.getSeconds() >= 24 && time.getSeconds() <= 59) {
          this.Digit6 = this.Digit6.replaceAt(3, digit3)
          this.Digit5 = this.Digit5.replaceAt(2, digit3)
          this.Digit4 = this.Digit4.replaceAt(1, digit3)
          this.Digit3 = this.Digit3.replaceAt(0, digit3)
        }
        if (time.getSeconds() >= 32 && time.getSeconds() <= 59) {
          this.Digit6 = this.Digit6.replaceAt(4, digit2)
          this.Digit5 = this.Digit5.replaceAt(3, digit2)
          this.Digit4 = this.Digit4.replaceAt(2, digit2)
          this.Digit3 = this.Digit3.replaceAt(1, digit2)
          this.Digit2 = this.Digit2.replaceAt(0, digit2)
        }
        if (time.getSeconds() >= 40 && time.getSeconds() <= 59) {
          this.Digit6 = this.Digit6.replaceAt(5, digit1)
          this.Digit5 = this.Digit5.replaceAt(4, digit1)
          this.Digit4 = this.Digit4.replaceAt(3, digit1)
          this.Digit3 = this.Digit3.replaceAt(2, digit1)
          this.Digit2 = this.Digit2.replaceAt(1, digit1)
          this.Digit1 = this.Digit1.replaceAt(0, digit1)
        }
        if (time.getSeconds() >= 0 && time.getSeconds() <= 7) {
          //random6digit
           this.Digit6 = this.Digit6.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(2, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(3, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(4, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(5, Math.floor(Math.random() * 10)+'')
           //random5digit
           this.Digit5 = this.Digit5.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(2, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(3, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(4, Math.floor(Math.random() * 10)+'')
           //random4digit
           this.Digit4 = this.Digit4.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit4 = this.Digit4.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit4 = this.Digit4.replaceAt(2, Math.floor(Math.random() * 10)+'')
           this.Digit4 = this.Digit4.replaceAt(3, Math.floor(Math.random() * 10)+'')
           //random3digit
           this.Digit3 = this.Digit3.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit3 = this.Digit3.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit3 = this.Digit3.replaceAt(2, Math.floor(Math.random() * 10)+'')
           //random2digit
           this.Digit2 = this.Digit2.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit2 = this.Digit2.replaceAt(1, Math.floor(Math.random() * 10)+'')
           //random1digit
           this.Digit1 = this.Digit1.replaceAt(0, Math.floor(Math.random() * 10)+'')
        }
        if (time.getSeconds() >= 8 && time.getSeconds() <= 15) {
          //random6digit
           this.Digit6 = this.Digit6.replaceAt(2, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(3, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(4, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(5, Math.floor(Math.random() * 10)+'')
           //random5digit
           this.Digit5 = this.Digit5.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(2, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(3, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(4, Math.floor(Math.random() * 10)+'')
           //random4digit
           this.Digit4 = this.Digit4.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit4 = this.Digit4.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit4 = this.Digit4.replaceAt(2, Math.floor(Math.random() * 10)+'')
           this.Digit4 = this.Digit4.replaceAt(3, Math.floor(Math.random() * 10)+'')
           //random3digit
           this.Digit3 = this.Digit3.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit3 = this.Digit3.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit3 = this.Digit3.replaceAt(2, Math.floor(Math.random() * 10)+'')
           //random2digit
           this.Digit2 = this.Digit2.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit2 = this.Digit2.replaceAt(1, Math.floor(Math.random() * 10)+'')
           //random1digit
           this.Digit1 = this.Digit1.replaceAt(0, Math.floor(Math.random() * 10)+'')
        }
        if (time.getSeconds() >= 16 && time.getSeconds() <= 23) {
          //random6digit
           this.Digit6 = this.Digit6.replaceAt(3, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(4, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(5, Math.floor(Math.random() * 10)+'')
           //random5digit
           this.Digit5 = this.Digit5.replaceAt(2, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(3, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(4, Math.floor(Math.random() * 10)+'')
           //random4digit
           this.Digit4 = this.Digit4.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit4 = this.Digit4.replaceAt(2, Math.floor(Math.random() * 10)+'')
           this.Digit4 = this.Digit4.replaceAt(3, Math.floor(Math.random() * 10)+'')
           //random3digit
           this.Digit3 = this.Digit3.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit3 = this.Digit3.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit3 = this.Digit3.replaceAt(2, Math.floor(Math.random() * 10)+'')
           //random2digit
           this.Digit2 = this.Digit2.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit2 = this.Digit2.replaceAt(1, Math.floor(Math.random() * 10)+'')
           //random1digit
           this.Digit1 = this.Digit1.replaceAt(0, Math.floor(Math.random() * 10)+'')
        }
        if (time.getSeconds() >= 24 && time.getSeconds() <= 31) {
          //random6digit
           this.Digit6 = this.Digit6.replaceAt(4, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(5, Math.floor(Math.random() * 10)+'')
           //random5digit
           this.Digit5 = this.Digit5.replaceAt(3, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(4, Math.floor(Math.random() * 10)+'')
           //random4digit
           this.Digit4 = this.Digit4.replaceAt(2, Math.floor(Math.random() * 10)+'')
           this.Digit4 = this.Digit4.replaceAt(3, Math.floor(Math.random() * 10)+'')
           //random3digit
           this.Digit3 = this.Digit3.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit3 = this.Digit3.replaceAt(2, Math.floor(Math.random() * 10)+'')
           //random2digit
           this.Digit2 = this.Digit2.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit2 = this.Digit2.replaceAt(1, Math.floor(Math.random() * 10)+'')
           //random1digit
           this.Digit1 = this.Digit1.replaceAt(0, Math.floor(Math.random() * 10)+'')
        }
        if (time.getSeconds() >= 32 && time.getSeconds() <= 39) {
          //random6digit
           this.Digit6 = this.Digit6.replaceAt(5, Math.floor(Math.random() * 10)+'')
           //random5digit
           this.Digit5 = this.Digit5.replaceAt(4, Math.floor(Math.random() * 10)+'')
           //random4digit
           this.Digit4 = this.Digit4.replaceAt(3, Math.floor(Math.random() * 10)+'')
           //random3digit
           this.Digit3 = this.Digit3.replaceAt(2, Math.floor(Math.random() * 10)+'')
           //random2digit
           this.Digit2 = this.Digit2.replaceAt(1, Math.floor(Math.random() * 10)+'')
           //random1digit
           this.Digit1 = this.Digit1.replaceAt(0, Math.floor(Math.random() * 10)+'')
        }
      }
    },
    waiting_digit() {
      let password = "";
      const keys = {
        number: "0123456789",
      };
      const getKey = [
        function number() {
          return keys.number[Math.floor(Math.random() * keys.number.length)];
        },
      ];
      const length = 6;

      while (length > password.length) {
        let keyToAdd = getKey[Math.floor(Math.random() * getKey.length)];
        password += keyToAdd();
      }
      let num_random = password;

      this.random_6 = num_random+"";
      this.random_5 = this.random_6.slice(1);
      this.random_4 = this.random_6.slice(2);
      this.random_3 = this.random_6.slice(3);
      this.random_2 = this.random_6.slice(4);
      this.random_1 = this.random_6.slice(5);

    },
    onScroll(e) {
      this.windowTop = e.target.documentElement.scrollTop;
      if (this.windowTop >= "200") {
        this.on_scroll = true;
      } else {
        this.on_scroll = false;
      }
    },
    onResize() {
      let windowResize = window.innerWidth;
      if (windowResize < 849) {
        this.on_resize = true;
      } else {
        this.on_resize = false;
      }
    },
    openNav() {
      document.getElementById("mySidenav").style.width = "250px";
      this.open_side_bar = true;
    },
    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
      this.open_side_bar = false;
    },
    async Init_config() {
      let Result = await this.axios.get(
        "https://api.laodls.com/api/v1/get_annountment/front"
      );
      this.setting = Result.data.res[0];
    },
    async Init_Data() {
      let Result = await this.axios.get(
        "https://api.laodls.com/api/v1/get_show/front"
      );
      this.ListDigit = Result.data.res[0];
      this.Digit6 = this.ListDigit.numberset + "";
      this.Digit5 = this.Digit6.slice(1);
      this.Digit4 = this.Digit6.slice(2);
      this.Digit3 = this.Digit6.slice(3);
      this.Digit2 = this.Digit6.slice(4);
      this.Digit1 = this.Digit6.slice(5);
    },
  },
  filters: {
    formatDate(value) {
      return moment(String(value)).format("YYYY-MM-DD");
    },
  },
};
</script>

<style>
.nav > li > a {
  padding-left: 20px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.95);
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
/* sidebar */
img.wp-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}
.bg {
  opacity: 0;
  transition: opacity 1s;
  -webkit-transition: opacity 1s;
}
.bg-loaded {
  opacity: 1;
}
:root {
  --primary-color: #e91025;
}
html {
  background-color: #f7f7f7 !important;
}
.sticky-add-to-cart--active,
#wrapper,
#main,
#main.dark {
  background-color: #ededed;
}
.header-main {
  height: 87px;
}
#logo img {
  max-height: 87px;
}
#logo {
  width: 207px;
}
.header-top {
  min-height: 45px;
}
.transparent .header-main {
  height: 265px;
}
.transparent #logo img {
  max-height: 265px;
}
.has-transparent + .page-title:first-of-type,
.has-transparent + #main > .page-title,
.has-transparent + #main > div > .page-title,
.has-transparent + #main .page-header-wrapper:first-of-type .page-title {
  padding-top: 295px;
}
.header.show-on-scroll,
.stuck .header-main {
  height: 70px !important;
}
.stuck #logo img {
  max-height: 70px !important;
}
.header-bg-color,
.header-wrapper {
  background-color: #ffffff;
}
.header-bottom {
  background-color: #f1f1f1;
}
.header-main .nav > li > a {
  line-height: 16px;
}
@media (max-width: 549px) {
  .header-main {
    height: 70px;
  }
  #logo img {
    max-height: 70px;
  }
}
.nav-dropdown {
  font-size: 100%;
}
.header-top {
  background-color: #e80000 !important;
} /* Color */
.accordion-title.active,
.has-icon-bg .icon .icon-inner,
.logo a,
.primary.is-underline,
.primary.is-link,
.badge-outline .badge-inner,
.nav-outline > li.active > a,
.nav-outline > li.active > a,
.cart-icon strong,
[data-color="primary"],
.is-outline.primary {
  color: #e91025;
} /* Color !important */
[data-text-color="primary"] {
  color: #e91025 !important;
} /* Background Color */
[data-text-bg="primary"] {
  background-color: #e91025;
} /* Background */
.scroll-to-bullets a,
.featured-title,
.label-new.menu-item > a:after,
.nav-pagination > li > .current,
.nav-pagination > li > span:hover,
.nav-pagination > li > a:hover,
.has-hover:hover .badge-outline .badge-inner,
button[type="submit"],
.button.wc-forward:not(.checkout):not(.checkout-button),
.button.submit-button,
.button.primary:not(.is-outline),
.featured-table .title,
.is-outline:hover,
.has-icon:hover .icon-label,
.nav-dropdown-bold .nav-column li > a:hover,
.nav-dropdown.nav-dropdown-bold > li > a:hover,
.nav-dropdown-bold.dark .nav-column li > a:hover,
.nav-dropdown.nav-dropdown-bold.dark > li > a:hover,
.is-outline:hover,
.tagcloud a:hover,
.grid-tools a,
input[type="submit"]:not(.is-form),
.box-badge:hover .box-text,
input.button.alt,
.nav-box > li > a:hover,
.nav-box > li.active > a,
.nav-pills > li.active > a,
.current-dropdown .cart-icon strong,
.cart-icon:hover strong,
.nav-line-bottom > li > a:before,
.nav-line-grow > li > a:before,
.nav-line > li > a:before,
.banner,
.header-top,
.slider-nav-circle .flickity-prev-next-button:hover svg,
.slider-nav-circle .flickity-prev-next-button:hover .arrow,
.primary.is-outline:hover,
.button.primary:not(.is-outline),
input[type="submit"].primary,
input[type="submit"].primary,
input[type="reset"].button,
input[type="button"].primary,
.badge-inner {
  background-color: #e91025;
} /* Border */
.nav-vertical.nav-tabs > li.active > a,
.scroll-to-bullets a.active,
.nav-pagination > li > .current,
.nav-pagination > li > span:hover,
.nav-pagination > li > a:hover,
.has-hover:hover .badge-outline .badge-inner,
.accordion-title.active,
.featured-table,
.is-outline:hover,
.tagcloud a:hover,
blockquote,
.has-border,
.cart-icon strong:after,
.cart-icon strong,
.blockUI:before,
.processing:before,
.loading-spin,
.slider-nav-circle .flickity-prev-next-button:hover svg,
.slider-nav-circle .flickity-prev-next-button:hover .arrow,
.primary.is-outline:hover {
  border-color: #e91025;
}
.nav-tabs > li.active > a {
  border-top-color: #e91025;
}
.widget_shopping_cart_content .blockUI.blockOverlay:before {
  border-left-color: #e91025;
}
.woocommerce-checkout-review-order .blockUI.blockOverlay:before {
  border-left-color: #e91025;
} /* Fill */
.slider .flickity-prev-next-button:hover svg,
.slider .flickity-prev-next-button:hover .arrow {
  fill: #e91025;
} /* Background Color */
[data-icon-label]:after,
.secondary.is-underline:hover,
.secondary.is-outline:hover,
.icon-label,
.button.secondary:not(.is-outline),
.button.alt:not(.is-outline),
.badge-inner.on-sale,
.button.checkout,
.single_add_to_cart_button,
.current .breadcrumb-step {
  background-color: #2b3991;
}
[data-text-bg="secondary"] {
  background-color: #2b3991;
} /* Color */
.secondary.is-underline,
.secondary.is-link,
.secondary.is-outline,
.stars a.active,
.star-rating:before,
.woocommerce-page .star-rating:before,
.star-rating span:before,
.color-secondary {
  color: #2b3991;
} /* Color !important */
[data-text-color="secondary"] {
  color: #2b3991 !important;
} /* Border */
.secondary.is-outline:hover {
  border-color: #2b3991;
}
.alert.is-underline:hover,
.alert.is-outline:hover,
.alert {
  background-color: #d7252a;
}
.alert.is-link,
.alert.is-outline,
.color-alert {
  color: #d7252a;
} /* Color !important */
[data-text-color="alert"] {
  color: #d7252a !important;
} /* Background Color */
[data-text-bg="alert"] {
  background-color: #d7252a;
}
body {
  font-size: 100%;
}
@media screen and (max-width: 549px) {
  body {
    font-size: 100%;
  }
}
body {
  font-family: "Prompt", sans-serif;
}
body {
  font-weight: 0;
}
body {
  color: #777777;
}
.nav > li > a {
  font-family: "Prompt", sans-serif;
}
.nav > li > a {
  font-weight: 700;
}
h1,
h2,
h3,
h4,
h5,
h6,
.heading-font,
.off-canvas-center .nav-sidebar.nav-vertical > li > a {
  font-family: "Prompt", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
.heading-font,
.banner h1,
.banner h2 {
  font-weight: 700;
}
.alt-font {
  font-family: "Dancing Script", sans-serif;
}
.alt-font {
  font-weight: 400 !important;
}
.pswp__bg,
.mfp-bg.mfp-ready {
  background-color: #272424;
}
.footer-2 {
  background-color: #af0000;
}
.absolute-footer,
html {
  background-color: #ef2626;
} /* Custom CSS */
* {
  font-family: Roboto, Phetsarath OT !important;
  font-weight: 600 !important;
}
.label-new.menu-item > a:after {
  content: "New";
}
.label-hot.menu-item > a:after {
  content: "Hot";
}
.label-sale.menu-item > a:after {
  content: "Sale";
}
.label-popular.menu-item > a:after {
  content: "Popular";
}
#image_345218859 {
  width: 100%;
}

#image_2102425197 {
  width: 100%;
}

#text-box-1835287412 .text-inner {
  padding: 15px 15px 15px 15px;
}
#text-box-1835287412 {
  width: 100%;
}
#text-box-1835287412 .text {
  font-size: 70%;
}

@media (min-width: 550px) {
  #text-box-1835287412 .text-inner {
    padding: 16px 15px 15px 15px;
  }
  #text-box-1835287412 {
    width: 91%;
  }
}

@media (min-width: 850px) {
  #text-box-1835287412 {
    width: 86%;
  }
  #text-box-1835287412 .text {
    font-size: 100%;
  }
}

#banner-1354816385 {
  padding-top: 121px;
  background-color: rgb(218, 18, 36);
}

@media (min-width: 550px) {
  #banner-1354816385 {
    padding-top: 180px;
  }
}

#text-box-1547869432 .text-inner {
  padding: 15px 15px 15px 15px;
}
#text-box-1547869432 {
  width: 100%;
}
#text-box-1547869432 .text {
  font-size: 70%;
}

@media (min-width: 550px) {
  #text-box-1547869432 .text-inner {
    padding: 16px 15px 15px 15px;
  }
  #text-box-1547869432 {
    width: 91%;
  }
}

@media (min-width: 850px) {
  #text-box-1547869432 {
    width: 86%;
  }
  #text-box-1547869432 .text {
    font-size: 100%;
  }
}

#banner-1573502091 {
  padding-top: 121px;
  background-color: rgb(218, 18, 36);
}

@media (min-width: 550px) {
  #banner-1573502091 {
    padding-top: 180px;
  }
}

#text-box-326039014 .text-inner {
  padding: 15px 15px 15px 15px;
}
#text-box-326039014 {
  width: 100%;
}
#text-box-326039014 .text {
  font-size: 70%;
}

@media (min-width: 550px) {
  #text-box-326039014 .text-inner {
    padding: 16px 15px 15px 15px;
  }
  #text-box-326039014 {
    width: 91%;
  }
}

@media (min-width: 850px) {
  #text-box-326039014 {
    width: 86%;
  }
  #text-box-326039014 .text {
    font-size: 100%;
  }
}

#banner-1124070651 {
  padding-top: 121px;
  background-color: rgb(218, 18, 36);
}

@media (min-width: 550px) {
  #banner-1124070651 {
    padding-top: 180px;
  }
}

#text-box-1337289486 .text-inner {
  padding: 16px 15px 15px 15px;
}
#text-box-1337289486 {
  width: 100%;
}
#text-box-1337289486 .text {
  font-size: 70%;
}

@media (min-width: 550px) {
  #text-box-1337289486 {
    width: 86%;
  }
}

@media (min-width: 850px) {
  #text-box-1337289486 .text {
    font-size: 100%;
  }
}

#banner-275630426 {
  padding-top: 130px;
  background-color: rgb(218, 18, 36);
}

@media (min-width: 550px) {
  #banner-275630426 {
    padding-top: 180px;
  }
}

#gap-1370901883 {
  padding-top: 30px;
}

#text-box-1143000626 .text-inner {
  padding: 15px 15px 15px 15px;
}
#text-box-1143000626 {
  width: 100%;
}
#text-box-1143000626 .text {
  font-size: 100%;
}

@media (min-width: 550px) {
  #text-box-1143000626 .text-inner {
    padding: 16px 15px 15px 15px;
  }
  #text-box-1143000626 {
    width: 60%;
  }
}

@media (min-width: 850px) {
  #text-box-1143000626 {
    width: 60%;
  }
  #text-box-1143000626 .text {
    font-size: 100%;
  }
}

#banner-874564019 {
  padding-top: 121px;
  background-color: rgb(218, 18, 36);
}

@media (min-width: 550px) {
  #banner-874564019 {
    padding-top: 180px;
  }
}

#text-box-676879988 .text-inner {
  padding: 15px 15px 15px 15px;
}
#text-box-676879988 {
  width: 100%;
}
#text-box-676879988 .text {
  font-size: 100%;
}

@media (min-width: 550px) {
  #text-box-676879988 .text-inner {
    padding: 16px 15px 15px 15px;
  }
  #text-box-676879988 {
    width: 60%;
  }
}

@media (min-width: 850px) {
  #text-box-676879988 {
    width: 60%;
  }
  #text-box-676879988 .text {
    font-size: 100%;
  }
}

#section_849591866 {
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: rgb(218, 18, 36);
}

#image_1825866440 {
  width: 100%;
}

#gap-254369011 {
  padding-top: 30px;
}

#image_1841725139 {
  width: 100%;
}

#image_606406179 {
  width: 100%;
}

#image_1386087555 {
  width: 100%;
}

#image_1699141243 {
  width: 100%;
}

#image_667230022 {
  width: 100%;
}

#image_1015217786 {
  width: 100%;
}

#gap-1081749030 {
  padding-top: 30px;
}

#section_2019356090 {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgb(255, 255, 255);
}

#image_539987042 {
  width: 100%;
}

#image_616291898 {
  width: 100%;
}

#image_684293623 {
  width: 100%;
}

#image_563397233 {
  width: 100%;
}

#image_2011948901 {
  width: 100%;
}

#image_1363647342 {
  width: 100%;
}

#image_425022596 {
  width: 100%;
}

#image_2011013779 {
  width: 100%;
}

#image_988064213 {
  width: 100%;
}

#image_733917722 {
  width: 100%;
}

#image_2097339612 {
  width: 100%;
}

#image_284959599 {
  width: 100%;
}

#image_1942370831 {
  width: 100%;
}

#image_1035090637 {
  width: 100%;
}

#image_2129949364 {
  width: 100%;
}

#image_145594713 {
  width: 100%;
}

#image_39447698 {
  width: 100%;
}

#image_1991450528 {
  width: 100%;
}

#image_1576831177 {
  width: 100%;
}

#image_769945379 {
  width: 100%;
}

#image_694535591 {
  width: 100%;
}

#image_583980146 {
  width: 100%;
}

#image_860154057 {
  width: 100%;
}

#image_668760854 {
  width: 100%;
}

#image_13567035 {
  width: 100%;
}

#image_626352136 {
  width: 100%;
}

#image_1080011068 {
  width: 100%;
}

#image_105823117 {
  width: 100%;
}

#image_1137055945 {
  width: 100%;
}

#image_1684923582 {
  width: 100%;
}

#image_435120288 {
  width: 100%;
}

#image_1178588392 {
  width: 100%;
}

#image_1834213094 {
  width: 100%;
}

#image_108964434 {
  width: 100%;
}

#image_1413532932 {
  width: 100%;
}

#image_1217815508 {
  width: 100%;
}

#image_201604736 {
  width: 100%;
}

#image_544794922 {
  width: 100%;
}

#image_78466746 {
  width: 100%;
}

#image_521573244 {
  width: 100%;
}

#image_1180606487 {
  width: 100%;
}

#image_1238290241 {
  width: 100%;
}

#image_1224103405 {
  width: 100%;
}

#image_1187890763 {
  width: 100%;
}

#image_822893468 {
  width: 100%;
}

#image_1115533052 {
  width: 100%;
}

#image_523245030 {
  width: 100%;
}

#image_902742444 {
  width: 100%;
}

#image_539902348 {
  width: 100%;
}

#image_1346103323 {
  width: 100%;
}

#image_1417074723 {
  width: 100%;
}

#image_1257351413 {
  width: 100%;
}

#image_42869771 {
  width: 100%;
}

#image_498716814 {
  width: 100%;
}

#image_728891839 {
  width: 100%;
}

#image_168909340 {
  width: 100%;
}

#image_154982338 {
  width: 100%;
}

#image_130992812 {
  width: 100%;
}

#image_1579032075 {
  width: 100%;
}

#image_497188135 {
  width: 100%;
}

#image_1712783211 {
  width: 100%;
}

#image_910079239 {
  width: 100%;
}

#image_1553667554 {
  width: 100%;
}

#image_2056555394 {
  width: 100%;
}

#section_1110333115 {
  padding-top: 43px;
  padding-bottom: 43px;
  min-height: 248px;
  background-color: rgb(246, 246, 246);
}

#section_1878830939 {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: rgb(193, 14, 31);
}

@media (min-width: 850px) {
  #section_1878830939 {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

#image_1144323901 {
  width: 100%;
}

#section_1331412847 {
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: rgb(190, 30, 45);
}

#image_599662668 {
  width: 100%;
}

#section_655344146 {
  padding-top: 13px;
  padding-bottom: 13px;
  min-height: 221px;
  background-color: rgb(190, 30, 45);
}

#row-566556277 > .col > .col-inner {
  padding: 5 0px 0px 0px;
}

#section_170196860 {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: rgb(255, 255, 255);
}

#image_210070846 {
  width: 100%;
}

#section_398681771 {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: rgb(43, 57, 144);
}

#image_1328984732 {
  width: 100%;
}

#section_934369606 {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: rgba(209, 204, 204, 0.36);
}
#section_934369606 .section-bg-overlay {
  background-color: rgba(244, 244, 244, 0.99);
}

#gap-1916758174 {
  padding-top: 30px;
}

#image_574056901 {
  width: 70%;
}

#image_1467393224 {
  width: 70%;
}

#image_648392836 {
  width: 70%;
}

#image_1605474114 {
  width: 70%;
}

#section_1627645518 {
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: 0px;
  background-color: rgb(255, 255, 255);
}

#image_534441232 {
  width: 100%;
}

#image_1861388853 {
  width: 100%;
}

#image_1365031943 {
  width: 100%;
}

#image_359071161 {
  width: 100%;
}

#image_1380705425 {
  width: 100%;
}

#image_1653903130 {
  width: 100%;
}

#image_1294688389 {
  width: 90%;
}

#image_984856891 {
  width: 90%;
}

#image_583595869 {
  width: 90%;
}

#image_147417259 {
  width: 90%;
}
.video-element_1 {
  width: 100%;
  max-width: 275px;
  margin: 0 0 10px 0;
  border: 0px solid #eeeeee;
  border-radius: 3px;
  outline: none;
  overflow: hidden;
  box-sizing: content-box;
}

.video-element_1 .image-block_1 {
  position: relative;
  width: 100%;
  max-width: 275px;
}

.video-element_1 .image-block_1 a {
  display: block;
  box-shadow: none !important;
}

.video-element_1 .image-block_1 img {
  width: 275px !important;
  height: auto;
  display: block;
  border-radius: 0 !important;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
}

.video-element_1 .image-block_1 img:hover {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
}

.video-element_1 .image-block_1 .play-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
}

.video-element_1 .image-block_1 .play-icon.youtube-icon {
  background: url(https://laodl.com/../wp-content/plugins/smart-grid-gallery/assets/images/admin_images/play.youtube.png)
    center center no-repeat;
}

.video-element_1 .image-block_1 .play-icon.vimeo-icon {
  background: url(https://laodl.com/../wp-content/plugins/smart-grid-gallery/assets/images/admin_images/play.vimeo.png)
    center center no-repeat;
}

.video-element_1 .title-block_1 {
  position: absolute;
  text-overflow: ellipsis;
  overflow: hidden;
  left: 0;
  width: 100%;
  height: 30px;
  bottom: -35px;
  color: #c02121;
  background: rgba(0, 0, 0, 0.8) !important;
  -webkit-transition: bottom 0.3s ease-out 0.1s;
  -moz-transition: bottom 0.3s ease-out 0.1s;
  -o-transition: bottom 0.3s ease-out 0.1s;
  transition: bottom 0.3s ease-out 0.1s;
}
.entry-content a {
  border-bottom: 0;
}
.video-element_1:hover .title-block_1 {
  bottom: 0;
}

.video-element_1 .title-block_1 a,
.video-element_1 .title-block_1 a:link,
.video-element_1 .title-block_1 a:visited {
  position: relative;
  margin: 0;
  padding: 0 1% 0 2%;
  width: 97%;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  z-index: 20;
  font-size: 16px;
  color: #c02121;
  font-weight: normal;
  box-shadow: none !important;
}

.video-element_1 .title-block_1 a:hover,
.video-element_1 .title-block_1 a:focus,
.video-element_1 .title-block_1 a:active {
  color: #ff2c2c;
  text-decoration: none;
}

.load_more4 {
  margin: 10px 0;
  position: relative;
  text-align: center;
  width: 100%;
}

.load_more_button4 {
  border-radius: 10px;
  display: inline-block;
  padding: 5px 15px;
  font-size: 16px !important;
  color: #ff0d0d !important;
  background: #5cadff !important;
  cursor: pointer;
}
.load_more_button4:hover {
  color: #ff4040 !important;
  background: #99c5ff !important;
}

.loading4 {
  display: none;
}
.paginate4 {
  font-size: 19px !important;
  color: #ff2c2c !important;
  text-align: center;
  margin-top: 25px;
}
.paginate4 a {
  border-bottom: none !important;
  box-shadow: none !important;
}
.icon-style4 {
  font-size: 21px !important;
  color: #b82020 !important;
}
.clear {
  clear: both;
}
#banner-136071884 {
  padding-top: 121px;
  background-color: rgb(218, 18, 36);
}

@media (min-width: 550px) {
  #banner-136071884 {
    padding-top: 180px;
  }
}
</style>
                          

