<template>
  <div>
    <div
      class="mfp-bg off-canvas off-canvas-left main-menu-overlay mfp-ready"
      :style="open_side_bar == false ? 'display: none;' : ''"
    ></div>
    <div
      class="mfp-wrap"
      :style="open_side_bar == false ? 'display: none;' : ''"
    >
      <div class="mfp-container" @click="closeNav()">
        <div id="mySidenav" class="sidenav">
          <div class="sidebar-menu no-scrollbar">
            <ul class="nav nav-sidebar nav-vertical nav-uppercase">
              <li
                class="header-search-form search-form html relative has-icon"
                style="padding: 20px"
              >
                <div class="header-search-form-wrapper">
                  <div
                    class="searchform-wrapper ux-search-box relative is-normal"
                  >
                    <form
                      method="get"
                      class="searchform"
                      action="https://laodl.com/"
                      role="search"
                    >
                      <div class="flex-row relative">
                        <div class="flex-col flex-grow">
                          <input
                            type="search"
                            class="search-field mb-0"
                            name="s"
                            value=""
                            id="s"
                            placeholder="Search&hellip;"
                          />
                        </div>
                        <div class="flex-col">
                          <button
                            type="submit"
                            class="
                              ux-search-submit
                              submit-button
                              secondary
                              button
                              icon
                              mb-0
                            "
                            aria-label="Submit"
                          >
                            <i class="icon-search"></i>
                          </button>
                        </div>
                      </div>
                      <div class="live-search-results text-left z-top"></div>
                    </form>
                  </div>
                </div>
              </li>
              <li
                class="
                  menu-item menu-item-type-custom menu-item-object-custom
                  current-menu-item current_page_item
                  menu-item-home menu-item-453
                "
              >
                <a href="/" aria-current="page">ໜ້າຫຼັກ</a>
              </li>
              <li
                class="
                  menu-item
                  menu-item-type-post_type
                  menu-item-object-page menu-item-268
                "
              >
                <a href="/previous-lotto">ຜົນອອກເລກ</a>
              </li>
              <li
                class="
                  menu-item
                  menu-item-type-post_type
                  menu-item-object-page menu-item-267
                "
              >
                <a href="">ຮູບພາບຜູ້ໂຊກດີ</a>
              </li>
              <li
                class="
                  menu-item
                  menu-item-type-post_type
                  menu-item-object-page menu-item-265
                "
              >
                <a href="">ເງື່ອນໄຂຮັບລາງວັນ</a>
              </li>
              <li
                class="
                  menu-item
                  menu-item-type-post_type
                  menu-item-object-page menu-item-1540
                "
              >
                <a href="">ໂປຼໂມຊັ້ນ</a>
              </li>
              <li
                class="
                  menu-item
                  menu-item-type-post_type
                  menu-item-object-page menu-item-1969
                "
              >
                <a href="">ວິທີການສະໝັກ</a>
              </li>
              <li class="html header-social-icons ml-0">
                <div class="social-icons follow-icons">
                  <a
                    href="https://www.facebook.com/dLaolottery/"
                    target="_blank"
                    data-label="Facebook"
                    rel="noopener noreferrer nofollow"
                    class="icon plain facebook tooltip"
                    title="Follow on Facebook"
                    ><i class="icon-facebook"></i></a
                  ><a
                    href="tel:021 41 42 43"
                    target="_blank"
                    data-label="Phone"
                    rel="noopener noreferrer nofollow"
                    class="icon plain phone tooltip"
                    title="Call us"
                    ><i class="icon-phone"></i
                  ></a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div id="wrapper">
      <header id="header" class="header has-sticky sticky-jump">
        <div class="header-wrapper" :class="on_scroll == true && on_resize == true? 'stuck':''">
          <div
            id="top-bar"
            class="header-top hide-for-sticky nav-dark hide-for-medium"
          >
            <div class="flex-row container">
              <div class="flex-col hide-for-medium flex-left">
                <ul
                  class="nav nav-left medium-nav-center nav-small nav-divided"
                ></ul>
              </div>

              <div class="flex-col hide-for-medium flex-center">
                <ul class="nav nav-center nav-small nav-divided"></ul>
              </div>

              <div class="flex-col hide-for-medium flex-right">
                <ul class="nav top-bar-nav nav-right nav-small nav-divided">
                  <li class="header-newsletter-item has-icon">
                    <a
                      href="#header-newsletter-signup"
                      class="tooltip"
                      title=""
                    >
                      <span class="header-newsletter-title hide-for-medium">
                        Tel. 021 41 42 43
                      </span>
                    </a>
                    <div
                      id="header-newsletter-signup"
                      class="
                        lightbox-by-id lightbox-content
                        mfp-hide
                        lightbox-white
                      "
                      style="max-width: 700px; padding: 0px"
                    >
                      <div class="row" id="row-218623792">
                        <div class="col small-12 large-12">
                          <div class="col-inner">
                            ທີ່ຢູ່:ຖະໜົນ 23 ສິງຫາ,ບ້ານ ໜອງບອນ,ເມືອງ ໄຊເສດຖາ,
                            ນະຄອນຫຼວງວຽງຈັນ, ໂທລະສັບ: 021 41 42 43. ແຟັກ: 021
                            450 081. Email:
                            <a
                              href="../cdn-cgi/l/email-protection.html"
                              class="__cf_email__"
                              data-cfemail="fc95929a93bcb8b09d93b0938888998e85d29f9391"
                              >[email&#160;protected]</a
                            >
                            Email:
                            <a
                              href="../cdn-cgi/l/email-protection.html"
                              class="__cf_email__"
                              data-cfemail="d6b2bab7b9e4e6e7e496beb9a2bbb7bfbaf8b5b9bb"
                              >[email&#160;protected]</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="html header-social-icons ml-0">
                    <div class="social-icons follow-icons">
                      <a
                        href="https://www.facebook.com/dLaolottery/"
                        target="_blank"
                        data-label="Facebook"
                        rel="noopener noreferrer nofollow"
                        class="icon plain facebook tooltip"
                        title="Follow on Facebook"
                        ><i class="icon-facebook"></i></a
                      ><a
                        href="tel:021 41 42 43"
                        target="_blank"
                        data-label="Phone"
                        rel="noopener noreferrer nofollow"
                        class="icon plain phone tooltip"
                        title="Call us"
                        ><i class="icon-phone"></i
                      ></a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="masthead" class="header-main">
            <div
              class="
                header-inner
                flex-row
                container
                logo-left
                medium-logo-center
              "
              role="navigation"
            >
              <!-- Logo -->
              <div id="logo" class="flex-col logo">
                <!-- Header logo -->
                <a
                  href="/"
                  title="หวยลาว ຫວຍດີຈິຕ້ອນ ສາຂາ3 ອິນຊີ Digital Lottery ผลหวยลาว - ຫວຍດີຈິຕ້ອນ ສາຂາ3 ອິນຊີ"
                  rel="home"
                >
                  <img
                    width="207"
                    height="87"
                    src="../wp-content/uploads/2019/10/Untitled-2-1.png"
                    class="header_logo header-logo"
                    alt="หวยลาว ຫວຍດີຈິຕ້ອນ ສາຂາ3 ອິນຊີ Digital Lottery ผลหวยลาว" /><img
                    width="207"
                    height="87"
                    src="../wp-content/uploads/2019/10/Untitled-2-1.png"
                    class="header-logo-dark"
                    alt="หวยลาว ຫວຍດີຈິຕ້ອນ ສາຂາ3 ອິນຊີ Digital Lottery ผลหวยลาว"
                /></a>
              </div>

              <!-- Mobile Left Elements -->
              <div class="flex-col show-for-medium flex-left">
                <ul class="mobile-nav nav nav-left">
                  <li class="nav-icon has-icon">
                    <div class="header-button">
                      <a
                        href="#"
                        data-open="#main-menu"
                        data-pos="left"
                        data-bg="main-menu-overlay"
                        data-color=""
                        class="icon primary button round is-small"
                        aria-label="Menu"
                        aria-controls="main-menu"
                        aria-expanded="false"
                        @click="openNav()"
                      >
                        <i class="icon-menu"></i>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>

              <!-- Left Elements -->
              <div class="flex-col hide-for-medium flex-left flex-grow">
                <ul
                  class="
                    header-nav header-nav-main
                    nav nav-left nav-divided nav-size-xlarge nav-uppercase
                  "
                >
                  <li
                    id="menu-item-453"
                    class="
                      menu-item
                      menu-item-type-custom
                      menu-item-object-custom
                      menu-item-home
                      menu-item-453
                    "
                  >
                    <a href="/" class="nav-top-link">ໜ້າຫຼັກ</a>
                  </li>
                  <li
                    id="menu-item-268"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page
                      current-menu-item
                      page_item page-item-257
                      current_page_item
                      menu-item-268
                      active
                    "
                  >
                    <a
                      href="/previous-lotto"
                      aria-current="page"
                      class="nav-top-link"
                      >ຜົນອອກເລກ</a
                    >
                  </li>
                  <li
                    id="menu-item-267"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-267
                    "
                  >
                    <a href="../lotto-winner/index.html" class="nav-top-link"
                      >ຮູບພາບຜູ້ໂຊກດີ</a
                    >
                  </li>
                  <li
                    id="menu-item-265"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-265
                    "
                  >
                    <a href="../lotto/index.html" class="nav-top-link"
                      >ເງື່ອນໄຂຮັບລາງວັນ</a
                    >
                  </li>
                  <li
                    id="menu-item-1540"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-1540
                    "
                  >
                    <a href="../promotions/index.html" class="nav-top-link"
                      >ໂປຼໂມຊັ້ນ</a
                    >
                  </li>
                  <li
                    id="menu-item-1969"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-1969
                    "
                  >
                    <a href="../dealer/index.html" class="nav-top-link"
                      >ວິທີການສະໝັກ</a
                    >
                  </li>
                </ul>
              </div>

              <!-- Right Elements -->
              <div class="flex-col hide-for-medium flex-right">
                <ul
                  class="
                    header-nav header-nav-main
                    nav nav-right nav-divided nav-size-xlarge nav-uppercase
                  "
                >
                  <li class="html header-button-1">
                    <div class="header-button">
                      <a
                        rel="noopener noreferrer"
                        href="https://line.me/R/ti/p/@qgr6804k"
                        target="_blank"
                        class="button primary"
                        style="border-radius: 99px"
                      >
                        <span>ສະໝັກຕົວແທນຂາຍ</span>
                      </a>
                    </div>
                  </li>

                  <li class="header-divider"></li>
                </ul>
              </div>

              <!-- Mobile Right Elements -->
              <div class="flex-col show-for-medium flex-right">
                <ul class="mobile-nav nav nav-right"></ul>
              </div>
            </div>

            <div class="container">
              <div class="top-divider full-width"></div>
            </div>
          </div>
          <div class="header-bg-container fill">
            <div class="header-bg-image fill"></div>
            <div class="header-bg-color fill"></div>
          </div>
        </div>
      </header>

      <main id="main" class="">
        <div id="content" class="content-area page-wrapper" role="main">
          <div class="row row-main">
            <div class="large-12 col">
              <div class="col-inner">
                <!-- <div class="row" id="row-604644503">
                  <div class="col small-12 large-12">
                    <div class="col-inner">
                      <div
                        class="img has-hover x md-x lg-x y md-y lg-y"
                        id="image_1564173851"
                      >
                        <div class="img-inner dark">
                          <img
                            width="600"
                            height="200"
                            src="../wp-content/uploads/2018/11/q4.png"
                            class="attachment-large size-large"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="container section-title-container">
                  <h3 class="section-title section-title-center">
                    <b></b
                    ><span class="section-title-main" style="font-size: 130%"
                      >ຜົນອອກເລກລາງວັນ ຫວຍພັດທະນາ</span
                    ><b></b>
                  </h3>
                </div>
                <div
                  v-for="(list, index) in ListDigit"
                  :key="index"
                  class="row"
                  id="row-1105949406"
                >
                  <div class="col small-12 large-12">
                    <div class="col-inner">
                      <div
                        id="gap-989108572"
                        class="gap-element clearfix"
                        style="display: block; height: auto"
                      ></div>

                      <h4 style="text-align: center">ຄັ້ງວັນ{{ list.work_date | formatDate }}</h4>
                      <div
                        id="gap-1499003292"
                        class="gap-element clearfix"
                        style="display: block; height: auto"
                      ></div>

                      <div class="row" id="row-2021107375">
                        <div class="col medium-4 small-12 large-4">
                          <div class="col-inner">
                            <p style="text-align: center">ເລກ 6 ໂຕ</p>
                            <h2 style="text-align: center">
                              {{ list.numberset }}
                            </h2>
                          </div>
                        </div>

                        <div class="col medium-4 small-12 large-4">
                          <div class="col-inner">
                            <p style="text-align: center">ເລກ 5 ໂຕ</p>
                            <h2 style="text-align: center">
                              {{ list.numberset | digit5 }}
                            </h2>
                          </div>
                        </div>

                        <div class="col medium-4 small-12 large-4">
                          <div class="col-inner">
                            <p style="text-align: center">ເລກ 4 ໂຕ</p>
                            <h2 style="text-align: center">
                              {{ list.numberset | digit4 }}
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div class="row" id="row-1483003076">
                        <div class="col medium-4 small-12 large-4">
                          <div class="col-inner">
                            <p style="text-align: center">ເລກ 3 ໂຕ</p>
                            <h2 style="text-align: center">
                              {{ list.numberset | digit3 }}
                            </h2>
                          </div>
                        </div>

                        <div class="col medium-4 small-12 large-4">
                          <div class="col-inner">
                            <p style="text-align: center">ເລກ 2 ໂຕ</p>
                            <h2 style="text-align: center">
                              {{ list.numberset | digit2 }}
                            </h2>
                          </div>
                        </div>

                        <div class="col medium-4 small-12 large-4">
                          <div class="col-inner">
                            <p style="text-align: center">ເລກ 1 ໂຕ</p>
                            <h2 style="text-align: center">
                              {{ list.numberset | digit1 }}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer id="footer" class="footer-wrapper">
        <!-- FOOTER 1 -->

        <!-- FOOTER 2 -->
        <div class="footer-widgets footer footer-2 dark">
          <div class="row dark large-columns-4 mb-0">
            <div id="block_widget-2" class="col pb-0 widget block_widget">
              <div class="row" id="row-680666733">
                <div class="col small-12 large-12">
                  <div class="col-inner">
                    <h2 style="text-align: left">
                      <strong>ຕິດຕໍ່ເຮົາ</strong>
                    </h2>
                  </div>
                </div>
              </div>
              <div class="row" id="row-765245161">
                <div class="col small-12 large-12">
                  <div class="col-inner" style="margin: -24px 0px -38px 0px">
                    <p>
                      ທ່ານໃດສົນໃຈຢາກເປັນຕົວແທນຈໍາໜ່າຍ ຫວຍພັດທະນາ
                      ສາມາດຕິດຕໍ່ໄດ້ທາງ<br />ບໍລິສັດ ອິນຊີ ເທຣດດີ້ງ ຈໍາກັດຜູ້ດຽວ
                      ຕົວແທນຈໍາໜ່າຍຫວຍພັດທະນາເລກ 3
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div id="block_widget-4" class="col pb-0 widget block_widget">
              <div class="row" id="row-1169355477">
                <div class="col small-12 large-12">
                  <div class="col-inner">
                    ທີ່ຢູ່:ຖະໜົນ 23 ສິງຫາ,ບ້ານ ໜອງບອນ,ເມືອງ ໄຊເສດຖາ,
                    ນະຄອນຫຼວງວຽງຈັນ, ໂທລະສັບ: 021 41 42 43. ແຟັກ: 021 450 081.
                    Email:
                    <a
                      href="../cdn-cgi/l/email-protection.html"
                      class="__cf_email__"
                      data-cfemail="cba2a5ada48b8f87aaa487a4bfbfaeb9b2e5a8a4a6"
                      >[email&#160;protected]</a
                    >
                    Email:
                    <a
                      href="../cdn-cgi/l/email-protection.html"
                      class="__cf_email__"
                      data-cfemail="5c38303d336e6c6d6e1c343328313d3530723f3331"
                      >[email&#160;protected]</a
                    >
                  </div>
                </div>
              </div>
            </div>

            <div id="block_widget-5" class="col pb-0 widget block_widget">
              <div class="row" id="row-2122660920">
                <div class="col small-12 large-12">
                  <div class="col-inner">
                    <p>ຕິດຕາມຜົນການອອກຫວຍໄດ້ກ່ອນໝູ່ ຕິດຕໍ່ເຮົາງ່າຍໆ</p>
                    <div class="row align-center" id="row-596990563">
                      <div class="col small-12 large-12">
                        <div
                          class="col-inner"
                          style="padding: 0 0px 0px 0px; margin: 0 0px 0px 0px"
                        >
                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_692312007"
                          >
                            <a class="" href="https://line.me/R/ti/p/@qgr6804k">
                              <div class="img-inner dark">
                                <img
                                  width="312"
                                  height="69"
                                  src="../wp-content/uploads/2021/06/line.png"
                                  class="attachment-large size-large"
                                  alt=""
                                />
                              </div>
                            </a>
                          </div>

                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_1437516720"
                          >
                            <a
                              class=""
                              href="https://www.messenger.com/t/598578720169375/?ref=pages_you_manage&amp;messaging_source=source%3Apages%3Amessage_shortlink"
                            >
                              <div class="img-inner dark">
                                <img
                                  width="312"
                                  height="69"
                                  src="../wp-content/uploads/2021/06/Mess.png"
                                  class="attachment-large size-large"
                                  alt=""
                                />
                              </div>
                            </a>
                          </div>

                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_743867955"
                          >
                            <a
                              class=""
                              href="https://api.whatsapp.com/send?phone=+8562058895533"
                            >
                              <div class="img-inner dark">
                                <img
                                  width="312"
                                  height="69"
                                  src="../wp-content/uploads/2021/06/whatapp.png"
                                  class="attachment-large size-large"
                                  alt=""
                                />
                              </div>
                            </a>
                          </div>

                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_453678472"
                          >
                            <a
                              class=""
                              href="https://www.youtube.com/channel/UC_hY7PHas5MHgf-T_d0I68w"
                            >
                              <div class="img-inner dark">
                                <img
                                  width="312"
                                  height="69"
                                  src="../wp-content/uploads/2021/06/youtube.png"
                                  class="attachment-large size-large"
                                  alt=""
                                />
                              </div>
                            </a>
                          </div>

                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_2087526942"
                          >
                            <a class="" href="https://vt.tiktok.com/ZSJqUySFk/">
                              <div class="img-inner dark">
                                <img
                                  width="312"
                                  height="69"
                                  src="../wp-content/uploads/2021/07/Tiktok.png"
                                  class="attachment-large size-large"
                                  alt=""
                                />
                              </div>
                            </a>
                          </div>

                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_1776409500"
                          >
                            <a class="" href="tel:021414243">
                              <div class="img-inner dark">
                                <img
                                  width="312"
                                  height="69"
                                  src="../wp-content/uploads/2021/06/CALL.png"
                                  class="attachment-large size-large"
                                  alt=""
                                />
                              </div>
                            </a>
                          </div>

                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_1399396054"
                          >
                            <a
                              class=""
                              href="https://m.me/dLaolottery/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div
                                class="img-inner dark"
                                style="margin: 0 0px 0px 0px"
                              ></div>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div class="col small-12 large-12">
                        <div
                          class="col-inner"
                          style="padding: 0 0px 0px 0px; margin: 0 0px 0px 0px"
                        >
                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_41154702"
                          >
                            <a
                              class=""
                              href="tel:02059605899"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div
                                class="img-inner dark"
                                style="margin: 0 0px 0px 0px"
                              ></div>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div class="col small-12 large-12">
                        <div class="col-inner">
                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_1417264046"
                          >
                            <a
                              class=""
                              href="tel:021414243"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div
                                class="img-inner dark"
                                style="margin: 0 0px 0px 0px"
                              ></div>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div class="col small-12 large-12">
                        <div class="col-inner">
                          <div
                            class="img has-hover x md-x lg-x y md-y lg-y"
                            id="image_1196921344"
                          >
                            <a class="" href="#">
                              <div
                                class="img-inner dark"
                                style="margin: 0 0px 0px 0px"
                              ></div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="block_widget-3" class="col pb-0 widget block_widget">
              <h3><strong>ສົນໃຈເປັນຕົວແທນຈໍາໜ່າຍ</strong></h3>
              <p>ເອກະສານປະກອບມີ</p>
              <ul>
                <li>ໃບຢັ້ງຢືນທີ່ຢູ່</li>
                <li>ໃບຮັບປະກັນ</li>
                <li>ກ໋ອບປີ້ບັດປະຈຳຕົວ</li>
                <li>ແຜນທີ່ບ້ານ</li>
                <li>ຮູບເຮືອນ</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="absolute-footer dark medium-text-center text-center">
          <div class="container clearfix">
            <div class="footer-primary pull-left">
              <div class="copyright-footer">
                Copyright 2021 © <strong>INSEE TRADING</strong>
              </div>
            </div>
          </div>
        </div>
        <a
          href="#top"
          class="
            back-to-top
            button
            icon
            invert
            plain
            fixed
            bottom
            z-1
            is-outline
            hide-for-medium
            circle
          "
          id="top-link"
          ><i class="icon-angle-up"></i
        ></a>
      </footer>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "Home",
  data() {
    return {
      baseURL:
        "https://opensheet.vercel.app/1gS9TveyBC2ae8Ns5jpThRAA6J2iAqaPwIrHpB10nfNI/previous",
      ListDigit: [],
      open_side_bar: false,
      windowTop: 0,
      on_scroll:false,
      on_resize:false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.onResize);
    this.Init_Data();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onScroll(e) {
      this.windowTop = e.target.documentElement.scrollTop;
      if (this.windowTop >= "200") {
        this.on_scroll = true
      } else {
        this.on_scroll = false
      }
    },
    onResize() {
      let windowResize = window.innerWidth;
      if (windowResize < 849) {
        this.on_resize = true
      } else {
        this.on_resize = false
      }
    },
    openNav() {
      document.getElementById("mySidenav").style.width = "250px";
      this.open_side_bar = true;
    },
    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
      this.open_side_bar = false;
    },
    async Init_Data() {
      this.axios
        .get("https://api.laodls.com/api/v1/get_previous")
        .then((response) => {
          this.ListDigit = response.data.res;
        });
    },
  },
  filters: {
    formatDate(value) {
      return moment(String(value)).format('YYYY-MM-DD')
    },
    digit5(value) {
      var string_digit = value+''
      return string_digit.slice(1) 
    },
    digit4(value) {
      var string_digit = value+''
      return string_digit.slice(2) 
    },
    digit3(value) {
      var string_digit = value+''
      return string_digit.slice(3) 
    },
    digit2(value) {
      var string_digit = value+''
      return string_digit.slice(4) 
    },
    digit1(value) {
      var string_digit = value+''
      return string_digit.slice(5) 
    }
  },
};
</script>
<style>
#image_1564173851 {
  width: 100%;
}
#image_1196921344 {
  width: 90%;
}

#image_692312007 {
  width: 100%;
}

#image_1437516720 {
  width: 100%;
}

#image_743867955 {
  width: 100%;
}

#image_453678472 {
  width: 100%;
}

#image_2087526942 {
  width: 100%;
}

#image_1776409500 {
  width: 100%;
}

#image_1399396054 {
  width: 90%;
}

#image_41154702 {
  width: 90%;
}
#image_1417264046 {
  width: 90%;
}
#row-1105949406 > .col > .col-inner {
  background-color: rgba(255, 255, 255, 0.99);
}
#gap-989108572 {
  padding-top: 30px;
}
#gap-1499003292 {
  padding-top: 30px;
}
</style>