<template>
  <router-view></router-view>
</template>

<script>
// css
require("../lib/plugins/fontawesome-free/css/all.min.css");
require("../lib/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css");
require("../lib/plugins/icheck-bootstrap/icheck-bootstrap.min.css");
require("../lib/plugins/jqvmap/jqvmap.min.css");
require("../lib/css/adminlte.min.css");
require("../lib/plugins/overlayScrollbars/css/OverlayScrollbars.min.css");
require("../lib/plugins/daterangepicker/daterangepicker.css");
require("../lib/plugins/summernote/summernote-bs4.min.css");
require("../lib/css/style.css");

require("./wp-includes/css/dist/block-library/style.min8b06.css?ver=5.4.7");
require("./wp-content/themes/flatsome/assets/css/fl-iconsa237.css?ver=3.11");
require("./wp-content/themes/flatsome/inc/integrations/ninjaforms/ninjaforms8b06.css?ver=5.4.7");
require("./wp-content/themes/flatsome/assets/css/flatsomef416.css?ver=3.11.0");
require("./wp-content/themes/flatsome/stylef416.css?ver=3.11.0");
require("./wp-content/plugins/smart-grid-gallery/assets/style/style8b06.css?ver=5.4.7");
require("./wp-content/plugins/smart-grid-gallery/assets/style/responsive_lightbox8b06.css?ver=5.4.7");
require("./wp-content/plugins/smart-grid-gallery/assets/style/css/font-awesome8b06.css?ver=5.4.7");
require("./wp-content/plugins/smart-grid-gallery/assets/style/colorbox-18b06.css?ver=5.4.7");
require("./wp-includes/css/dashicons.min8b06.css?ver=5.4.7");
require("./wp-content/plugins/ninja-forms/assets/css/display-structure8b06.css?ver=5.4.7");

// javascript
require("../lib/plugins/bootstrap/js/bootstrap.bundle.min.js");
require("../lib/plugins/chart.js/Chart.min.js");
require("../lib/plugins/sparklines/sparkline.js");
require("../lib/plugins/jquery-knob/jquery.knob.min.js");
require("../lib/plugins/daterangepicker/daterangepicker.js");
require("../lib/plugins/summernote/summernote-bs4.min.js");
require("../lib/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js");
require("../lib/js/adminlte.js");
require("./wp-includes/js/hoverIntent.minc245.js?ver=1.8.1")
require("../src/main.js");

export default {
    name: 'App',
    data () {
      return {}
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
