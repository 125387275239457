import Vue from 'vue';
import Router from 'vue-router';

import Home from '../views/Home.vue';
import Prevoiuslotto from '../views/previous-lotto.vue';

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL || '/',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/previous-lotto',
      name: 'Prevoiuslotto',
      component: Prevoiuslotto
    },
  ]
});


export default router;

